import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import {connect} from 'react-redux'
import SidebarAccount from '../Components/SidebarAccount';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ToastsContainer, ToastsStore} from 'react-toasts';

function Wishlist(props){
  const [wishlist, setWishlist] = useState({data: []});

  let session = JSON.parse(localStorage.getItem('APP_SESSION'));
  if(session['type'] != 221){
    window.location.href = '/account';
  }
  const getWishlist = () => {
    API.getDataWishlist(session['service_id']).then((res) => {
        setWishlist(res.data);
    });
  }
  const deleteWishlist = (id) => {
    if(window.confirm('Delete This Wishlist Item?') == true){
      API.postRemoveWishlistItem({'id': id}).then((res) => {
        if(res.data.status == true){
          getWishlist();
          ToastsStore.success("Wishlist Item Successfully Deleted");
          window.location.reload();
        }else{
          ToastsStore.error("Error. Something Wrong.");
        }
      });
    }
  }
  useEffect(() => {
      getWishlist();
  },[]);
  return (
    <Fragment>
      <Header />
        <div className="row">
          <div className="col-md-2">
            <SidebarAccount />
          </div>
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <table className="table table-bordered table-striped" style={{fontSize: 14,fontWeight: 600}}>
                  <tr>
                    <td>No</td>
                    <td colSpan="2">Part Info</td>
                    <td>Model</td>
                    <td>Qty Stock</td>
                    <td>Price</td>
                    <td>Action</td>
                  </tr>
                  {wishlist.data.map((v,i) => {
                    return(
                      <tr>
                        <td width="10">{(i+1)}</td>
                          <td width="20">{v.picture == null ? <img src="no-image.png" width="50" />:<img src={props.rootUrl+v.picture} width="100" />}</td>
                          <td width="300"><a href={"/view/"+v.model_id}>{v.part_name}</a></td>
                          <td width="150">{v.model}</td>
                          <td width="140">{v.qty > 0 ? <span class="badge badge-success">In Stock</span> : <span class="badge badge-danger">Out Of Stock</span>}</td>
                          <td width="140">Rp.{v.price}</td>
                          <td><button className="btn btn-transparent btn-link btn-xs" onClick={() => deleteWishlist(v.id)}><i class="fas fa-trash"></i> Delete</button></td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
    </Fragment>
  )
}
const mapStateToProps = (state) => {
  return{
    rootUrl: state.rootUrl
  }
}
export default connect(mapStateToProps)(Wishlist);
