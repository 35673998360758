import React,{Fragment,useState,useEffect} from 'react';
import Header from '../Components/Header';
import Content from './Content';
import Search from './Search';
import {
    useParams
} from "react-router-dom";

function Home(props) {
    let param = useParams();
    const [searchData, setSearchData] = useState({
        modelName: null,
        partNumber: null,
        partName: null
    });
    localStorage.setItem('APP_RELOAD',0);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState({
        search: false
    });

    function searchByModel(event){
        event.preventDefault();
        if(searchData.modelName !== null){
            setSearch({
                search: true
            });
            window.location.href = "/search/byModel/"+searchData.modelName;
            //window.location.reload();
        }
    }
    function searchByPartCode(event){
        event.preventDefault();
        if(searchData.partNumber !== null){
            setSearch({
                search: true
            });
            let id = searchData.partNumber.split(' ').join('+');
            window.location.href = "/search/byPartNumber/"+id;
            //window.location.reload();
        }
    }
    function searchByPartName(event){
        event.preventDefault();
        if(searchData.partName !== null){
            setSearch({
                search: true
            });
            let id = searchData.partName.split(' ').join('+');
            window.location.href = "/search/byPartName/"+id;
            //window.location.reload();
        }
    }
    function onChange(e){
        setSearchData({
            [e.target.name]: e.target.value
        })
    }
    function handleClose(){
        props.setModal(false);
    }
    useEffect(() => {
        var time_now  = (new Date()).getTime();
        localStorage.setItem('lastclear', time_now);
        var lastclear = localStorage.getItem('lastclear');
        if ((time_now - lastclear) > 1000 * 60 * 60 * 1) {
            localStorage.clear();
            localStorage.setItem('lastclear', time_now);
        }
        document.title = 'E-Catalog Modena';
        document.keyword = 'modena,modena indonesia,modena ecatalog,modena.co.id,air.modena.co.id';
        document.description = 'E-Catalog Modena';

        if(sessionStorage.getItem('MAIN_URL') !== null)
        {
            window.location.href = sessionStorage.getItem('MAIN_URL');
        }
    },[])
    return (
            <Fragment>
                <Header />
                <div className="container-custom">
                    <div className="row search-row" style={{padding: 0,marginTop: 10}}>
                    <div className="col-md-4" style={{marginLeft: '0 !important'}}>
                        <form onSubmit={searchByModel}>
                            <div className="input-group mb-0">
                            <input type="text" className="form-control custom-input" placeholder="Search By Model" name="modelName" onChange={onChange} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary custom-button" type="submit" id="button-addon2">
                                <i className="fas fa-search"></i>
                                </button>
                            </div>
                            </div>
                        </form>
                        <small className="small-text">BK 7112</small>
                    </div>
                    <div className="col-md-4" style={{marginLeft: '0px !important'}}>
                        <form onSubmit={searchByPartCode}>
                        <div className="input-group mb-0">
                        <input type="text" className="form-control custom-input" placeholder="Search By Part Number" name="partNumber" onChange={onChange} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary custom-button" type="submit" id="button-addon2">
                            <i className="fas fa-search"></i>
                            </button>
                        </div>
                        </div>
                        <small className="small-text">KBKATBSB00011018 / KBK-AT-BSB-0001-1018</small>
                        </form>
                    </div>
                    <div className="col-md-4" style={{marginLeft: '0px !important'}}>
                        <form onSubmit={searchByPartName}>
                        <div className="input-group mb-0">
                        <input type="text" className="form-control custom-input" placeholder="Search By Part Name" name="partName" onChange={onChange} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary custom-button" type="submit" id="button-addon2">
                            <i className="fas fa-search"></i>
                            </button>
                        </div>
                        </div>
                        <small className="small-text">Bracket Spring Base</small>
                        </form>
                    </div>
                    </div>
                </div>

                {props.content == 'Search' || param.path != undefined ? <Search param={searchData} status={false} /> : <Content />}
            </Fragment>
    )
}

export default Home;
