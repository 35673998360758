import React,{Fragment,useState,useEffect} from 'react';
import API from '../../Services/Api';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import './custom.css';

const Blank = (props) => {
    const [segment, setSegment] = useState({
        data: []
    });
    const [category, setCategory] = useState({
        data: []
    })
    const [originalParts, setOriginal] = useState({
        data: []
    })
    const [subtitutionParts, setSubtitutions] = useState({
        data: []
    })
    const [model, setModel] = useState('');
    const [loadingOriginal, setLoadingOriginal] = useState(false);
    const [loadingSubtitutions, setloadingSubtitutions] = useState(false);
    const [searchOriginal, setSearchOriginal] = useState(false);
    const [searchSubtitutions, setSearchSubtitutions] = useState(false);
    const [qty, setQty] = useState(0);
    const session = JSON.parse(localStorage.APP_SESSION);

    const addToCart = (modelId,partId,stock) => {
        if(stock < 1){
          ToastsStore.warning("Sorry, This Item Not Available.");
        }else{
          let postData = {
            'konsumen_id': session['service_id'],
            'part_id': partId,
            'model_id': modelId,
            'qty': stock
          }
          API.postInsertCart(postData).then((res) => {
              if(res.data.status == true){
                ToastsStore.success(stock+' Item Added To Your Basket');
                setQty(0);
                props.setCart(res.data.item);
              }
          }).catch((e) => {
            console.log(e);
          });

        }
    }
    const getSegment = () => {
        API.getAllSegment().then((res) => {
           setSegment(res.data);
        });
    }
    const getCategory = (e) => {
      API.getCategoryBySegment(e).then((res) => {
        setCategory({data: []})
        setCategory(res.data);
      });
    }
    const addToWishlist = (customer_id,model_id,part_id) => {
        let data = {'customer_id': customer_id,'model_id': model_id,'part_id': part_id};
        API.postInsertWishlist(data).then((res) => {
            if(res.data.status == true){
              props.setWishlist(res.data.count);
              ToastsStore.success(res.data.msg);
            }else{
              ToastsStore.error(res.data.msg);
            }
        });
    }
    const getOriginalParts = (e) => {
        setLoadingOriginal(true);
        setSearchOriginal(true);
        API.getPartsOriginal(e).then((res) => {
          setOriginal(res.data);
          setSubtitutions({
            data: []
          });
          setLoadingOriginal(false);
        });
    }
    const getPartSubtitution = (id) => {
      setSearchSubtitutions(true);
      API.getPartsSubtitution(id).then((res) => {
          setSubtitutions(res.data);
      });
    }
    const changeModel = (e) => {
      setModel(e.target.value);
    }
    const searchModel = (e) => {
      e.preventDefault();
      setLoadingOriginal(true);
      setSearchOriginal(true);
      API.getModelOriginal(model).then((res) => {
        setOriginal(res.data);
        setSubtitutions({
          data: []
        });
        setLoadingOriginal(false);
      });
    }
    const selectQty = (e) => {
      setQty(e.target.value);
    }
    useEffect(() => {
        getSegment();
    },[]);
    return (
        <Fragment>
            <div className="ml-3 mr-3 pt-2" style={{width: '100% !important'}}>
                <div className="row">
                    <div className="col-md-2 pl-0">
                        <div className="col-md-12 pl-0 pr-0 mb-2" style={{marginLeft: '0px !important'}}>
                                <form>
                                <label style={pkStyle.label}>Segment</label>
                                <div className="input-group mb-0">
                                <select className="form-control custom-input" onChange={e => getCategory(e.target.value)}>
                                    <option>Select Segment</option>
                                    {segment.data.map((v,i) => (
                                        <option value={v.s_id} key={i}>{v.name}</option>
                                    ))}
                                </select>
                                </div>
                                </form>
                        </div>
                        <div className="col-md-12 pl-0 pr-0 mb-2" style={{marginLeft: '0px !important'}}>
                                <form>
                                <label style={pkStyle.label}>Category</label>
                                <div className="input-group mb-0">
                                <select className="form-control custom-input" onChange={e => getOriginalParts(e.target.value)}>
                                    <option>Select Category</option>
                                    {category.data.map((v,i) => (
                                      <option value={v.c_id} key={i}>{v.name}</option>
                                    ))}
                                </select>
                                </div>
                                </form>
                        </div>
                        <div className="col-md-12 pl-0 pr-0 mb-2" style={{marginLeft: '0 !important'}}>
                            <form onSubmit={searchModel}>
                                <label style={pkStyle.label}>Search By Part Code</label>
                                <div className="input-group mb-0">
                                <input type="text" className="form-control custom-input" placeholder="Search" name="partModel" onChange={e => changeModel(e)} />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary custom-button" type="submit" id="button-addon2">
                                    <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4 pl-0 pr-0" id="original-items" style={pkStyle.columns}>
                        <div className="col-lg-12" style={pkStyle.pk_header}>
                          <span>Original Parts</span>
                        </div>
                        {!searchOriginal ?
                        <div style={pkStyle.defaultLabel} id="defaultOriginals">
                          {loadingOriginal == true ? <center><Loader type="ThreeDots" color="white" height={20} width={35} /></center> : <center>Select Parts Segment & Category First..</center>}
                        </div>: '' }
                        {originalParts.data.length == 0 && searchOriginal ?
                            <div style={pkStyle.defaultLabel} id="parts">
                              <center><i className="fas fa-search"></i> Oops,No Parts Found..</center>
                            </div> : ''}
                        {originalParts.data.map((v,i) => (
                          <div className="row pb-1 items" key={i} style={{padding: '10px',borderBottom: '1px solid #ddd',paddingBottom: '5px'}} onClick={() => getPartSubtitution(v.original_id)}>
                            <div className="col-md-4 pr-0">
                              <img src={`${props.rootUrl}`+v.part_picture} width="100%" className="img-fluid" style={pkStyle.pk_image} />
                            </div>
                            <div className="col-md-8">
                              <h5 style={pkStyle.pk_title}>{v.part_name}</h5>
                              <span style={pkStyle.pk_subtitle2}>PRODUCT : {v.part_category}</span><br/>
                              <span style={pkStyle.pk_subtitle2}>CATEGORY : {v.part_group} - {v.part_segment}</span><br/>
                              <span style={pkStyle.pk_subtitle2}>PART CODE : {v.part_code}</span><br/>
                              <span style={pkStyle.pk_subtitle2,{color: '#333',fontWeight: 'bolder'}}>PRICE : {v.part_price}</span>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="col-md-6  pl-0 pr-0" style={pkStyle.columns} id="subtitutions-items">
                      <div className="col-lg-12" style={pkStyle.pk_header}>
                        <span>Subtitutions Parts</span>
                      </div>
                        {!searchSubtitutions ?
                        <div style={pkStyle.defaultLabel} id="defaultSubtitutions">
                          {loadingSubtitutions == true ? <center><Loader type="ThreeDots" color="orange" height={20} width={35} /></center> : <center>Select Parts Segment & Category First..</center>}
                        </div>: ''}
                        {subtitutionParts.data.length == 0 && searchSubtitutions ?
                            <div style={pkStyle.defaultLabel} id="parts">
                              <center><i className="fas fa-search"></i> Oops,No Parts Found..</center>
                            </div> : ''}
                            {subtitutionParts.data.map((v,i) => (
                              <div className="row pb-1 items2" key={i} style={{padding: '10px',borderBottom: '1px solid #ddd',paddingBottom: '5px'}}>
                                <div className="col-md-3 pr-0">
                                  <img src={`${props.rootUrl}`+v.part_picture} width="100%" className="img-fluid" style={pkStyle.pk_image} />
                                </div>
                                <div className="col-md-9">
                                  <h5 style={pkStyle.pk_title}>{v.part_name} <small></small></h5>
                                  <span style={pkStyle.pk_subtitle2}>PART CODE : {v.part_code}</span><br/>
                                  <span style={pkStyle.pk_subtitle2}>PRODUCT : {v.part_category}</span><br/>
                                  <span style={pkStyle.pk_subtitle2}>CATEGORY : {v.part_group} - {v.part_segment}</span><br/>
                                  <span style={pkStyle.pk_subtitle2}>REMARK : {v.remark}</span><br/>
                                  <span style={pkStyle.pk_subtitle,{color: '#333',fontWeight: 'bolder'}}>Rp. {v.part_price}</span><br/>
                                </div>
                              </div>
                            ))}
                    </div>

                </div>
            </div>
            <ToastsContainer store={ToastsStore}/>
        </Fragment>
    )
}
const pkStyle = {
  label: {
    fontFamily: 'Gotham',
    fontSize: '12px',
    fontWeight: 'bolder'
  },
  columns: {
    border: '1px solid #ccc',
    backgroundColor:'#fff',
    maxHeight: '500px',
    overflowX: 'scroll'
  },
  defaultLabel: {
    padding: '10px',
    backgroundColor: '#f9a51b',
    color: '#fff',
    fontSize: '13px',
    fontFamily: 'Gotham',
    width: '75%',
    margin: '12.5%',
    marginTop: '50px'
  },
  pk_title: {
    fontFamily: 'Gotham',
    marginBottom: 5,
    fontSize: 16,
    fontWeight: 'bolder'
  },
  pk_subtitle: {
    fontFamily: 'Gotham',
    fontSize: 12,
    width: '100%'
  },
  pk_subtitle2: {
    fontFamily: 'Gotham',
    fontSize: 11
  },
  pk_subtitle3: {
    fontFamily: 'Gotham',
    fontSize: 12
  },
  pk_image: {
    width: '90%',
    border: '1px solid #ccc',
    marginBottom: '5px'
  },
  anchorXs: {
    fontSize: '12px'
  },
  pk_header: {
    background: 'orange',
    textAlign: 'center',
    fontFamily: 'Gotham',
    padding: '5px',
    fontWeight: 'bolder',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#fff',
  }
}
const dispatchToProps = (dispatch) => {
    return {
        setCart: (qty) => dispatch({type: 'SET_CART',cart: qty}),
        setWishlist: (items) => dispatch({type: 'SET_WISHLIST',wishlistItems: items}),
        setListLoading: (status) => dispatch({type: 'SET_LIST_LOADING',listLoading: status})
    }
}
const stateToProps = (state) => {
  return {
    rootUrl: state.rootUrl
  }
}
export default connect(stateToProps,dispatchToProps)(Blank);
