
import react,{useState} from 'react';
import API from '../../Services/Api';
let appData = JSON.parse(window.localStorage.getItem('APP_PAYMENT_DATA'));

const checkPayment = (order) => {
  let data;
  return new Promise((resolve,reject) => {
    API.getMidtransStatus(order.orderId).then((res) => {

      if(res.data.transaction_status === 'expire'){
        resolve({'transaction_status': res.data.transaction_status,'message': 'Your Transaction Process Has Been Expired'});
        API.postUpdateOrderData({id: order.idOrder,status: 6}).then((response) => {
          API.postUpdateOrderInfo({
            id: order.idOrderInfo,
            order_id: order.orderId,
            order_data: JSON.stringify(res.data),
            transaction_status: res.data.transaction_status,
            status_code: res.data.status_code,
            created_date: res.data.transaction_time,
            payment_type: res.data.payment_type}).then((res) => {});
        })
      }else if(res.data.transaction_status === 'pending'){
        resolve({'transaction_status': res.data.transaction_status,'message': 'Your Transaction Will Be Process','data': res.data});
        API.postUpdateOrderData({id: order.idOrder,status: 0}).then((response) => {
          API.postUpdateOrderInfo({
            id: order.idOrderInfo,
            order_id: order.orderId,
            order_data: JSON.stringify(res.data),
            transaction_status: res.data.transaction_status,
            status_code: res.data.status_code,
            created_date: res.data.transaction_time,
            payment_type: res.data.payment_type}).then((res) => {});
        })
      }else if(res.data.transaction_status === 'settlement'){
        resolve({'transaction_status': res.data.transaction_status,'message': 'Payment Success, Settling Your Transaction'});
        API.postUpdateOrderData({id: order.idOrder,status: 1}).then((response) => {
          API.postUpdateOrderInfo({
            id: order.idOrderInfo,
            order_id: order.orderId,
            order_data: JSON.stringify(res.data),
            transaction_status: res.data.transaction_status,
            status_code: res.data.status_code,
            created_date: res.data.transaction_time,
            payment_type: res.data.payment_type}).then((res) => {});
        })
      }else if(res.data.transaction_status === 'cancel'){
        resolve({'transaction_status': res.data.transaction_status,'message': 'Your Transaction Process Has Canceled'});
        API.postUpdateOrderData({id: order.idOrder,status: 6}).then((response) => {
          API.postUpdateOrderInfo({
            id: order.idOrderInfo,
            order_id: order.orderId,
            order_data: JSON.stringify(res.data),
            transaction_status: res.data.transaction_status,
            status_code: res.data.status_code,
            created_date: res.data.transaction_time,
            payment_type: res.data.payment_type}).then((res) => {});
        })
      }

    });
  });
}
const getPaymentCharge = (data) => {
    if(data.payment_type === 'cimb_clicks'){
      let requestBody = {
          "payment_type": "cimb_clicks",
          "transaction_details": {
              "gross_amount": data.amount,
              "order_id": data.order_id
          },
          "customer_details": {
              "email": appData.email,
              "first_name": appData.nama_konsumen,
              "last_name": "",
              "phone": appData.telepon
          },
          "cimb_clicks": {
            "description": "Purchase of a Modena Items"
          }
      }

    }
}

const FnPayment = {
  checkPayment
}
export default FnPayment;
