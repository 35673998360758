import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    this.props.callback(e.currentTarget.value);

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.props.callbackValue(e.currentTarget.value);
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: this.props.type === 'whsName' ? e.currentTarget.innerText.split(' ')[0] : e.currentTarget.innerText
    });
    
    if(this.props.type === 'whsName') {
      this.props.callbackValue(e.currentTarget.innerText.split(' ')[0]);
    } else {
      this.props.callbackValue(e.currentTarget.innerText);
    }
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    
    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
    
  };
  closeSuggetion = () => {
    setTimeout(() => {
      this.setState({showSuggestions: false})
    }, 500);
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;
    let placeholder;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      }else{
        suggestionsListComponent = (
          <></>
        )
      }
    }
    if(this.props.type === 'model'){
      placeholder = 'Search Model';
    }else if(this.props.type === 'partNumber'){
      placeholder = 'Search Part Number';
    }else if(this.props.type === 'partName'){
      placeholder = 'Search Part Name';
    }else if (this.props.type === 'whsName') {
      placeholder = 'Search Warehouse';
    }

    return (
      <Fragment>
        <input
          type="text"
          className="form-control custom-input"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          value={userInput}
          onBlur={() => this.closeSuggetion()}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;