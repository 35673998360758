import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {connect} from 'react-redux';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Button,Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Autocomplete from './../Components/Autocomplete';
import { search as searchIcon, box } from "../Images";

const Accs = (props) => {
    let session = JSON.parse(localStorage.getItem('APP_SESSION'));
    const [search, setSearch] = useState({data: []});
    const [data, setData] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [offset, setOffset] = useState(1);
    const [showData, setShowData] = useState([]);
    const [limit, setLimit] = useState(15);
    const [show, setShow] = useState(false);
    const [showStatusOrder, setShowStatusOrder] = useState(false);
    const [showModelList, setShowModelList] = useState(false);
    const [statusOrder, setStatusOrder] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [itemCode, setItemCode] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [modelOptions, setModelOptions] = useState([]);
    const [modelValue, setModelValue] = useState(null);
    const [partOptions, setPartOptions] = useState([]);
    const [partValue, setPartValue] = useState(null);
    const [partNameOptions, setPartNameOptions] = useState([]);
    const [whsNameOptions, setWhsNameOptions] = useState([]);
    const [partNameValue, setPartNameValue] = useState(null);
    const [isLoadingModel, setLoadingModel] = useState(false);
    const [isLoadingPartNumber, setLoadingPartNumber] = useState(false);
    const [isLoadingPartName, setLoadingPartName] = useState(false);
    const columns = [
        {
            name: 'No',
            selector: 'no',
            sortable: true,
            width: '2%'
        },
        {
            name: 'Part Code',
            selector: 'ItemCode',
            sortable: true,
            width: '10%'
        },
        {
            name: 'Part Name',
            selector: 'ItemName',
            sortable: true,
            width: '16%'
        },
        {
            name: 'Model',
            selector: 'model',
            sortable: true,
            width: '5%',
            cell: row => <center><div className={{fontsize: '11px'}}><img onClick={() => openModelList(row.ItemCode)} src={box} alt="box-model" style={{width: 50, height: 50, borderRadius: "50%", padding: 5, cursor: "pointer"}} /></div></center>
        },
        {
            name: 'Price',
            selector: 'Price',
            sortable: true,
            width: '9%',
            sortFunction: (a, b) => {
                a = Number((a.Price.split(',').join('')).replace('.00', '')); // Converting into number
                b = Number((b.Price.split(',').join('')).replace('.00', '')); // Converting into number
                if (a.length === b.length) {
                  return a > b ? 1 : -1; // same length condition
                }
                return a.length > b.length ? 1 : -1; // comparing length of string
             }
        },
        {
            name: 'Warehouse',
            selector: 'WhsCode',
            sortable: true,
            width: '7%'
        },
        {
            name: 'Warehouse Name',
            selector: 'WhsName',
            sortable: true,
            width: '15%'
        },
        {
            name: 'Locator',
            selector: 'BinCode',
            sortable: true,
            width: '10%'
        },
        {
            name: 'Branch Stock',
            selector: 'OnHand',
            sortable: true,
            width: '5%',
            cell: row => <center>{parseFloat(row.OnHand)}</center>,
            sortFunction: (a, b) => {
                a = Number(a.OnHand);
                b = Number(b.OnHand);
                if (a.length === b.length) {
                  return a > b ? 1 : -1;
                }
                return a.length > b.length ? 1 : -1;
             }
        },
        {
            name: 'PMT Stock',
            selector: 'Available',
            sortable: true,
            width: '5%',
            cell: row => <center>{parseFloat(row.Available)}</center>,
            sortFunction: (a, b) => {
                a = Number(a.Available);
                b = Number(b.Available);
                if (a.length === b.length) {
                  return a > b ? 1 : -1;
                }
                return a.length > b.length ? 1 : -1;
             }
        },
        {
            name: 'National Stock',
            selector: 'AvailNation',
            sortable: true,
            width: '5%',
            cell: row => <center><div className={{fontsize: '11px'}}>{parseFloat(row.AvailNation)}</div></center>,
            sortFunction: (a, b) => {
                a = Number(a.AvailNation);
                b = Number(b.AvailNation);
                if (a.length === b.length) {
                  return a > b ? 1 : -1;
                }
                return a.length > b.length ? 1 : -1;
             }
        },
        {
            name: 'Discontinue',
            selector: 'Discountinue',
            sortable: true,
            width: '5%',
            cell: row => <center><div style={ row.Discountinue == 'Y' ? {fontsize: '11px', fontWeight: 'bold'} : {fontsize: '11px'}}>{row.Discountinue}</div></center>
        },
        {
            name: 'Status Order',
            selector: 'status_order',
            sortable: true,
            width: '5%',
            cell: row => <center><div className={{fontsize: '11px'}}><img onClick={() => openStatusOrder(row.ItemCode)} src={searchIcon} alt="search-model" style={{width: 50, height: 50, borderRadius: "50%", padding: 5, cursor: "pointer"}} /></div></center>
        },
        {
            name: 'Image',
            selector: 'image',
            cell: row => row.part_picture === null ? <div><center>-</center></div> : <a className="btn btn-xs btn-info" style={{color: '#fff'}} onClick={() => openImage(row.ItemCode, row.part_picture)}>View</a>,
            width: '5%'
        },
    ];

    const onFilter = (v) => {
        let param = v.target.value;
        const results = data.filter(res =>
            res.ItemCode.toLowerCase().includes(v.target.value.toLowerCase()) || 
            res.ItemName.toLowerCase().includes(v.target.value.toLowerCase()) || 
            res.Price.toLowerCase().includes(v.target.value.toLowerCase()) || 
            res.WhsCode.toLowerCase().includes(v.target.value.toLowerCase()) ||
            res.BinCode.toLowerCase().includes(v.target.value.toLowerCase()) ||
            res.Discountinue.toLowerCase().includes(v.target.value.toLowerCase())
        );

        if(v.target.value == ''){
            setTimeout(() => {
                setDataFiltered(data); 
            }, 2000);
            
        }else{
            setTimeout(() => {
                setDataFiltered(results); 
            }, 2000);
        } 
    }
    const onChange = (v,type) => {
        if(type === 'model'){
            setSearch({data: {...search.data,modelName: v,gudang: session.gudang_id}});
        }else if(type === 'partNumber'){
            setSearch({data: {...search.data,partNumber: v,gudang: session.gudang_id}});
        }else if(type === 'partName'){
            setSearch({data: {...search.data,partName: v,gudang: session.gudang_id}});
        }else if(type === 'whsName'){
            setSearch({data: {...search.data,whsName: v,gudang: session.gudang_id}});
        }
    }
    const onSearch = () => {
        setLoading(true);
        localStorage.setItem('APP_SEARCH_PARAM',JSON.stringify(search.data));
        API.postPartSearch(JSON.parse(localStorage.getItem('APP_SEARCH_PARAM'))).then((res) => {
            setLoading(false);
            setData(res.data);
            setDataFiltered(res.data);
        });
    }
    const searchModel = (v) => {
        API.postSearchOptions({model: v}).then((res) => {
            setLoadingPartNumber(false);
            setModelOptions(res.data);
        });
    };
    const searchPartNumber = selectedOption => {
        API.postSearchOptions({partNumber: selectedOption}).then((res) => {
            setLoadingPartName(false);
            setPartOptions(res.data);
        });
    };
    const searchPartName = selectedOption => {
        API.postSearchOptions({partName: selectedOption}).then((res) => {
            setLoadingPartNumber(false);
            setPartNameOptions(res.data);
        });
    };
    const searchWhsName = selectedOption => {
        API.postSearchOptions({ whsName: selectedOption }).then((res) => {
            setLoadingPartNumber(false);
            setWhsNameOptions(res.data);
        });
    };
    const convertPrice = (price) => {
        if(price === null){
            return 0;
        }else{
            let number_string = price.toString();
            let sisa 	= number_string.length % 3;
            let rupiah 	= number_string.substr(0, sisa);
            let ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
            if (ribuan) {
                let separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }
            return rupiah;
        }
    }

    const openImage = (itemno,link) => {
        setShow(true);
        if(itemno.length < 17){
            setImgUrl(link);
        }else{
            setImgUrl(link);
        }
    }

    const openStatusOrder = (ItemCode) => {
        API.getStatusOrder({ itemCode: ItemCode }).then((res) => {
            setStatusOrder(res.data);
            setItemCode(ItemCode);
            setShowStatusOrder(true);
        });
    }

    const openModelList = (ItemCode) => {
        API.getModelList({ itemCode: ItemCode }).then((res) => {
            setModelList(res.data);
            setItemCode(ItemCode);
            setShowModelList(true);
        });
    }

    useEffect(() => {
        props.title('PART SEARCH');
        if(sessionStorage.getItem('MAIN_URL') !== null)
        {
            window.location.href = sessionStorage.getItem('MAIN_URL');
        }
    },[]);

    return (
        <>
            <Header line="line-title" />
            <div className="container-custom" style={{paddingTop: 30}}>
                <div className="row search-row" style={{padding: 0}}>
                    <div className="col-md-3" style={{marginLeft: '0 !important'}}>
                            <div className="input-group mb-0">
                            <input type='text' className="form-control" placeholder="Model" style={{borderRadius: 0}} onChange={e => onChange(e.target.value,'model')} />
                            </div>
                        <small className="small-text">BK 7112</small>
                    </div>
                    <div className="col-md-4" style={{marginLeft: '0px !important'}}>
                        <div className="input-group mb-0">
                        <input type='text' className="form-control" placeholder="Part Number" style={{borderRadius: 0}} onChange={e => onChange(e.target.value,'partNumber')} />
                        </div>
                        <small className="small-text">KBKATBSB00011018 / KBK-AT-BSB-0001-1018</small>
                    </div>
                    <div className="col-md-4" style={{marginLeft: '0px !important'}}>
                        <div className="input-group mb-0">
                        <input type='text' className="form-control" placeholder="Part Name" style={{borderRadius: 0}} onChange={e => onChange(e.target.value,'partName')} />
                        </div>
                        <small className="small-text">Bracket Spring Base</small>
                    </div>
                    <div className="col-md-1" style={{marginLeft: '0px !important'}}>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary custom-button" type="button" id="button-addon2" onClick={() => onSearch()} style={{width: '100%'}}>
                            <i className="fas fa-search"></i>
                            &nbsp;&nbsp;Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-3 mr-3" style={{width: '100% !important',paddingTop: 10}}>
                <div className="row">
                    <div className="col-md-12 pl-0 pr-0">
                        <DataTable
                            style={{borderTop: '1px solid #ccc',fontSize: '12px'}}
                            columns={columns}
                            data={dataFiltered}
                            pagination={true}
                            noHeader={false}
                            paginationPerPage={50}
                            fixedHeader={false}
                            title="Part Search"
                            subHeader={true}
                            striped={true}
                            isSearchable={true}
                            paginationRowsPerPageOptions={[10,20,30,50,100]}
                            subHeaderComponent={
                                <>
                                    <div className="md-form mt-2 mb-2">
                                        <input className="form-control" type="text" placeholder="Search Part" onChange={(text) => onFilter(text)} aria-label="Search" style={{width: 300,border: '1px solid #999',borderRadius: 0}} />
                                    </div>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              size="sm"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Part Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center><img src={props.csmsURL+imgUrl} width="100%"  onError={e => { e.currentTarget.src = props.oldCsms+imgUrl; }} /></center>
              </Modal.Body>
            </Modal>
            <Modal
              show={loading}
              size="sm"
            >
              <Modal.Body>
                <center><Loader
                        type="ThreeDots"
                        color="orange"
                        height={60}
                        width={70}
                    /></center>
              </Modal.Body>
            </Modal>

            <Modal
              show={showStatusOrder}
              onHide={() => setShowStatusOrder(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Status Order ({itemCode})
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {
                    statusOrder.length > 0 
                        ? 
                            <div className='p-2'>
                                {
                                    statusOrder.length <= 5
                                        ?
                                            statusOrder.map((item, i) => {
                                                return (
                                                    <div className='card p-2 mb-2' key={i}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td width={150}>ETA</td>
                                                                    <td>:</td>
                                                                    <td>{item.ETA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={150}>Status Order</td>
                                                                    <td>:</td>
                                                                    <td>{item.STATUS_ORDER}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td width={150}>Current Status</td>
                                                                    <td>:</td>
                                                                    <td>{item.CURRENT_STATUS}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            })
                                        : 
                                            <div style={{height: 500, overflow: 'auto', overflowY: 'scroll'}}>
                                                {
                                                    statusOrder.map((item, i) => {
                                                        return (
                                                            <div className='card p-2 mb-2' key={i}>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width={150}>ETA</td>
                                                                            <td>:</td>
                                                                            <td>{item.ETA}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width={150}>Status Order</td>
                                                                            <td>:</td>
                                                                            <td>{item.STATUS_ORDER}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width={150}>Current Status</td>
                                                                            <td>:</td>
                                                                            <td>{item.CURRENT_STATUS}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                }
                            </div>
                        : 
                            <div>
                                There is no orders yet!..
                            </div>
                }
              </Modal.Body>
            </Modal>

            <Modal
              show={showModelList}
              onHide={() => setShowModelList(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Model List Of ({itemCode})
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {
                modelList.length > 0 
                    ? 
                        <div className='p-2'>
                            {
                                modelList.length <= 15
                                    ?
                                        modelList.map((item, i) => {
                                            return (
                                                <div className='card p-2 mb-2' key={i}>
                                                    {item.model_name}
                                                </div>
                                            )
                                        })
                                    : 
                                        <div style={{height: 500, overflow: 'auto', overflowY: 'scroll'}}>
                                            {
                                                modelList.map((item, i) => {
                                                    return (
                                                        <div className='card p-2 mb-2' key={i}>
                                                            {item.model_name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                            }
                        </div>
                    : 
                        <div>
                            There is no model that use this part yet!..
                        </div>
                }
              </Modal.Body>
            </Modal>
        </>
    )
}
const style = {
    regiterLabel: {
      fontSize: '13px',
      fontWeight: 600
    },
    customOptions: {
        width: '100%'
    }
}
const stateToProps = (state) => {
    return {
      modelImage: state.modelImage,
      rootUrl: state.rootUrl,
      csmsURL: state.csmsURL,
      oldCsms: state.oldCsms
    }
  }
const mapDispatch = (dispatch) => {
    return {
        title: (title) => dispatch({type: "GET_TITLE",title: title})
    }
}
export default connect(stateToProps,mapDispatch)(Accs);