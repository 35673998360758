import React,{Fragment,useState,useEffect} from 'react';
import API from './../Services/Api';
import Header from './../Components/Header';
import Blank from './PartPage/Blank';
import {connect} from 'react-redux';
import {
    useHistory,
    useParams
} from "react-router-dom";

const PartKnowlegde = (props) => {
    let param = useParams();
    const [searchData, setSearchData] = useState({
        partBarcode: null,
        modelNumber: null,
        partName: null
    });
    const [segment, setSegment] = useState({
        data: []
    });
    const [search, setSearch] = useState(false);
    const onChange = (e) => {
        setSearchData({
            [e.target.name]: e.target.value
        })
    }
    const getSegment = () => {
        API.getAllSegment().then((res) => {
           setSegment(res.data);
        });
    }
    useEffect(() => {
        getSegment();
        props.title('Parts Knowledge');
        document.getElementById('main-menu').value = '#'+props.match.path;
    },[]);
    return(
        <Fragment>
            <Header line="line-title" />

            <div className="container-custom pt-4">
                    <div className="row">
                        <div className="col-md-12">
                            {props.content == 'PartsKnowledge' || props.match.path !== '/parts-knowledge'? null : <Blank />}
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}
const mapDispatch = (dispatch) => {
    return {
        title: (title) => dispatch({type: "GET_TITLE",title: title})
    }
}
export default connect(null,mapDispatch)(PartKnowlegde);
