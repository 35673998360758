import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import SidebarAccount from '../Components/SidebarAccount';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ToastsContainer, ToastsStore} from 'react-toasts';

function Account(props){
  const [data, setData] = useState([]);
  const [province,setProvinces] = useState({data: []});
  const [town,setTown] = useState({data: []});
  const [district, setDistrict] = useState({data: []});
  const [subDistrict, setSubDistrict] = useState({data: []});
  const [postalCode, setPostalCode] = useState('');
  const [kelurahan, setKelurahan] = useState('');
  let session = JSON.parse(localStorage.getItem('APP_SESSION'));

  const getCity = (id) => {
    API.getCity(id).then((res) => {
      setTown(res.data);
    });
  }
  const getKecamatan = (id) => {
    API.getKecamatan(id).then((res) => {
      setDistrict(res.data);
    });
  }
  const getKelurahan = (id) => {
    API.getKelurahan(id).then((res) => {
      setSubDistrict(res.data);
    });
  }
  const getPostalCode = (id) => {
    API.getPostalCode(id).then((res) => {
      setPostalCode(res.data);
      document.getElementById('kodepos').focus();
      document.getElementById('kodepos').value = res.data;
      setData({
        ...data,
        kode_pos: res.data,
        kode_pos_idx: id
      });
    });
  }

  const onChange = (v) => {
    if(v.target.value == ''){
      document.getElementById(v.target.name).classList.add('is-invalid');
    }else{
      if(v.target.name == 'email'){
        if(v.target.value == ''){
          setData({
            ...data,
            email: data.email
          });
        }else{
          API.postCheckEmail({email: v.target.value,konsumen_id: session['service_id']}).then((res) => {
            if(res.data != 0){
              ToastsStore.error("Email cannot be used.");
              document.getElementById('email').value = data.email;
              document.getElementById('email').focus();
            }else{
              ToastsStore.success("Email can be used.");
            }
          }).catch((e) => {
            console.log(e);
          });
        }
      }
      if(v.target.name == 'province_idx'){
        setTown({data: []});
        setDistrict({data: []});
        setSubDistrict({data: []});
        getCity(v.target.value);
      }
      if(v.target.name == 'city_idx'){
        setDistrict({data: []});
        setSubDistrict({data: []});
        getKecamatan(v.target.value);
      }
      if(v.target.name == 'kecamatan_idx'){
        setSubDistrict({data: []});
        getKelurahan(v.target.value);
      }
      if(v.target.name == 'kelurahan_idx'){
        getPostalCode(v.target.value);
        setKelurahan(v.target.value);
      }
      setData({
        ...data,
        [v.target.name]: v.target.value
      });
    }
    console.log(data);
  };

  const options = {
  title: 'Logout',
  message: 'Logout From Your Account?',
  buttons: [
    {
      label: 'Yes',
      onClick: () => {
        localStorage.setItem('APP_SESSION','{"status": false}');
        window.location.reload();
      }
    },
    {
      label: 'No',
      onClick: () => {
        console.log('Failed To Logout')
      }
    }
  ],
};
const update = () => {
  API.postUpdate(data).then((res) => {
    if(res.data.status == true){
      ToastsStore.success("Your Data Has Been Updated.");
    }else{
      ToastsStore.error("Update Failed, Something Wrong.");
    }
  });
}
  useEffect(() => {
    API.getProvinces().then((res) => {
      setProvinces(res.data);
    });
    /*API.getDataKonsumen(session['service_id']).then((res) => {
      setData(res.data.data[0]);
      getCity(res.data.data[0].province_idx);
      getKecamatan(res.data.data[0].city_idx);
      getKelurahan(res.data.data[0].kecamatan_idx);
    });*/

  },[])
  return(
    <Fragment>
      <Header />
        <div className="row">
          <div className="col-md-2">
            <SidebarAccount />
          </div>
          <div className="col-md-8">
            {(() => {
              let session = JSON.parse(localStorage.getItem('APP_SESSION'));
              if(session['status'] == true){
                if(session['type'] != '221'){
                  return (<><div className="alert alert-info"><center><i className="fas fa-exclamation"></i> This Page For Customers Only</center></div></>)
                }else{
                  return (
                    <>
                      <div className="card">
                        <div className="card-header">
                          Your Account Data
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label style={style.regiterLabel}>Email</label>
                                <input type="email" className="form-control" name="email" id="email" defaultValue={data.email} onBlur={onChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label style={style.regiterLabel}>Full Name</label>
                                <input type="text" className="form-control" name="nama_konsumen" defaultValue={data.nama_konsumen} onChange={onChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label style={style.regiterLabel}>Title / Gender</label>
                                <select className="form-control" name="jenis_kelamin" id="jenis_kelamin" onChange={onChange}>
                                  <option value="">Select A Title</option>
                                  <option value="L" selected={data.jenis_kelamin == '121' ? true : false}>Mr.</option>
                                  <option value="P" selected={data.jenis_kelamin == '122' ? true : false}>Mrs.</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12"></div>
                              <div className="col-md-6 mb-2">
                                <label style={style.regiterLabel}>Identity Type* (KTP/NPWP)</label>
                                <select className="form-control" name="jenis_nik" id="jenis_nik" onChange={onChange}>
                                  <option>Select Identity Type</option>
                                  <option value="511" selected={data.jenis_nik == '511' ? true : false}>NPWP</option>
                                  <option value="512" selected={data.jenis_nik == '512' ? true : false}>KTP</option>
                                </select>
                              </div>
                              <div className="col-md-6 mb-2">
                                <label style={style.regiterLabel}>Identity Number*</label>
                                <input type="number" className="form-control mb-2" id="no_nik" name="no_nik" defaultValue={data.no_nik} onChange={onChange} />
                              </div>
                            <div className="col-md-12">
                              <h4 style={style.registerTitle}>Address Information</h4>
                              <hr/>
                            </div>

                            <div className="col-md-6 mb-3">
                              <label style={style.regiterLabel}>Province*</label>
                              <select className="form-control" name="province_idx" id="province_idx" onChange={onChange}>
                                <option value="">Select Province</option>
                                {province.data.map((v,i) => (
                                  <option value={v.id} key={i} selected={data.province_idx == v.id ? true : false}>{v.text}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label style={style.regiterLabel}>City*</label>
                              <select className="form-control" name="city_idx" id="city_idx" onChange={onChange}>
                                <option value="">Select City</option>
                                {town.data.map((v,i) => (
                                  <option value={v.id} key={i} selected={data.city_idx == v.id ? true : false}>{v.text}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label style={style.regiterLabel}>District*</label>
                              <select className="form-control" name="kecamatan_idx" id="kecamatan_idx" onChange={onChange}>
                                <option value="">Select District</option>
                                {district.data.map((v,i) => (
                                  <option value={v.id} key={i} selected={data.kecamatan_idx == v.id ? true : false}>{v.text}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-5 mb-2">
                              <label style={style.regiterLabel}>Sub-District*</label>
                              <select className="form-control" name="kelurahan_idx" id="kelurahan_idx" onChange={onChange}>
                                <option value="">Select Sub-District</option>
                                {subDistrict.data.map((v,i) => (
                                  <option value={v.id} key={i} selected={data.kelurahan_idx == v.id ? true : false}>{v.text}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-3 mb-2">
                              <label style={style.regiterLabel}>Postal Code</label>
                              <input type="number" className="form-control mb-2" id="kodepos" name="kodepos" defaultValue={data.kode_pos} onBlur={onChange} />
                            </div>
                            <div className="col-md-12 mb-2">
                              <label style={style.regiterLabel}>Full Address*</label>
                              <textarea name="alamat" id="alamat" className="form-control" onChange={onChange} defaultValue={data.alamat}>{data.alamat}</textarea>
                            </div>
                            <div className="col-md-12">
                            <h4 style={style.registerTitle}>Contact Information</h4>
                              <hr/>
                            </div>
                            <div className="col-md-6 mb-2">
                              <label style={style.regiterLabel}>Number Type</label>
                              <select className="form-control" name="jenis" id="jenis" onChange={onChange}>
                                <option value="">Select Contact Phone Type</option>
                                <option value="1" selected={data.jenis == 1 ? true : false}>Home Number</option>
                                <option value="2" selected={data.jenis == 2 ? true : false}>Phone Number</option>
                                <option value="3" selected={data.jenis == 3 ? true : false}>Fax Number</option>
                              </select>
                            </div>
                            <div className="col-md-6 mb-4">
                              <label style={style.regiterLabel}>Contact Number</label>
                              <input type="number" className="form-control" id="telepon" name="telepon" defaultValue={data.telepon} onChange={onChange} />
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <button className="btn btn-info btn-sm pull-right" style={{float: 'right'}} onClick={() => update()}>Save Information</button>
                        </div>
                      </div>
                    </>
                  )
                }
              }
            })()}
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
    </Fragment>
  )
}
const style = {
  smallLink : {
    fontSize: 12,
    fontWeight: 'bolder',
    fontFamily: 'Gotham'
  },
  regiterLabel: {
    fontSize: '13px',
    fontWeight: 600
  },
  registerTitle: {
    fontSize: 17,
    marginTop: 15,
    fontFamily: 'Gotham'
  },
}
export default Account;
