import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button,Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

function Bulletin(props){
  const [segment, setSegment] = useState([]);
  const [category, setCategory] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const [content, setContent] = useState('');

  const getSegment = () => {
    API.getAllSegment().then((res) => {
      setSegment(res.data.data);
    });
  }
  const changeSegment = (e) => {
    API.getCategoryBySegment(e.target.value).then((res) => {
      setCategory(res.data.data);
    })
  }
  const changeCategory = (e) => {
    API.getBulletins(e.target.value).then((res) => {
      setData(res.data);
    });
  }
  const openInfo = (info) => {
      setContent(info);
      setStatus(true);
  }
  const onFilter = (v) => {
    let param = v.target.value;
        const results = data.filter(res =>
            res.current_part_id.toLowerCase().includes(v.target.value) || res.new_part_id.toLowerCase().includes(v.target.value) || res.model_name.toLowerCase().includes(v.target.value) || res.start_lot.toLowerCase().includes(v.target.value)  || res.finish_lot.toLowerCase().includes(v.target.value)
        );
        if(v.target.value == ''){
            setTimeout(() => {
              setDataFiltered(data); 
            }, 1000);
            
        }else{
            setTimeout(() => {
              setDataFiltered(results); 
            }, 1000);
        } 
  }
  const columns = [
    {
        name: 'No',
        selector: 'no',
        sortable: true,
        width: '5%'
    },
    {
        name: 'Model',
        selector: 'model_name',
        sortable: true,
        width: '15%'
    },
    {
        name: 'Curr.Part Code',
        selector: 'current_part_id',
        width: '10%'
    },
    {
        name: 'New Part Code',
        selector: 'new_part_id',
        width: '10%'
    },
    {
        name: 'Part Name',
        selector: 'part_name',
        sortable: true,
        width: '10%'
    },
    {
        name: 'Content',
        selector: 'content',
        sortable: true,
        width: '20%'
    },
    {
        name: 'Category',
        selector: 'category',
        sortable: true,
        width: '10%',
    },
    {
        name: 'Start Lot',
        selector: 'start_lot',
        sortable: true,
        width: '5%'
    },
    {
        name: 'Finish Lot',
        selector: 'finish_lot',
        sortable: true,
        width: '5%',
    },
    {
        name: 'Image',
        selector: 'image',
        cell: row => (<>
                      <button className="btn btn-sm btn-primary" onClick={() => openInfo(row.content)}>Info</button>&nbsp;&nbsp;
                      <a href={`${row.filename}`} target="_blank" className="btn btn-sm btn-success">View File</a>
                      </>),
        width: '10%'
    },
  ];

  useEffect(() => {
    getSegment();
    let session = JSON.parse(localStorage.getItem('APP_SESSION'));
    API.getBulletins('0').then((res) => {
      setData(res.data.data);
      setDataFiltered(res.data.data);
    });
  },[])
  return (
    <Fragment>
      <Header />
      <div className="row" >
        <div className="col-md-12">

        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body" style={{overflow: 'auto'}}>
            <div style={style.cardTitle}><i className="fas fa-info-circle"></i> Data Services Update</div>
            <DataTable
                            style={{borderTop: '1px solid #ccc',fontSize: '12px'}}
                            columns={columns}
                            data={dataFiltered}
                            pagination={true}
                            noHeader={true}
                            paginationPerPage={50}
                            fixedHeader={true}
                            title="Part Search"
                            subHeader={true}
                            striped={true}
                            isSearchable={true}
                            paginationRowsPerPageOptions={[10,20,30,50,100]}
                            subHeaderComponent={
                                <>
                                    <div className="md-form mt-2 mb-2">
                                        <input className="form-control" type="text" placeholder="Search Part" onChange={(text) => onFilter(text)} aria-label="Search" style={{width: 300,border: '1px solid #999',borderRadius: 0}} />
                                    </div>
                                </>
                            }
                        />
            </div>
          </div>
        </div>
      </div>
      <Modal show={status} onHide={() => setStatus(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                {content}
                </div>
            </Modal.Body>
        </Modal>
    </Fragment>
  )
}
const style = {
  cardTitle: {
    width: '100%',
    fontSize: 15,
    fontWeight: 'Gotham',
    fontWeight: 600,
    paddingBottom: 10,
    borderBottom: '1px solid orange',
    marginBottom: 10
  }
}
export default Bulletin;
