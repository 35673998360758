import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import Invoice from '../Accounts/Invoice';
import SidebarAccount from '../Components/SidebarAccount';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import ReactInterval from 'react-interval';
import { Button,Modal } from 'react-bootstrap';
import FnPayment from '../Services/Functions/Payment';
import {connect} from 'react-redux';

const Orders = (props) => {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [interval, doInterval] = useState(false);
  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [id, setId] = useState();
  const [paymentData, setPaymentData] = useState([]);
  const [chargeData, setChargeData] = useState([]);
  const [ids, setIds] = useState({});
  const [orderId, setOrderId] = useState({
    old_order_id: null,
    new_order_id: null,
    order_item_id: null,
    order_info_id: null
  });

  let session = JSON.parse(localStorage.getItem('APP_SESSION'));
  var appData = JSON.parse(window.localStorage.getItem('APP_PAYMENT_DATA'));

  const getOrders = () => {
    API.getOrders(session.service_id).then((res) => {
      setData(res.data);
    })
  }
  const getOrder = (id) => {
    setId(id);
    setShow(true);
  }
  const openMidtrans = (idOrder,idOrderItem,idOrderInfo,new_order_id,old_order_id) => {
    API.getOrderId(old_order_id,session.service_id).then((res) => {
      setOrderData(res.data.data);
      let paymentData = {email: appData.email,nama_konsumen: appData.nama_konsumen,order_id: new_order_id,telepon:appData.telepon,total: res.data.data.rawtotal};
      localStorage.setItem('APP_PAYMENT_DATA',JSON.stringify(paymentData));
      var requestBody =
      {
        transaction_details: {
          gross_amount: res.data.data.rawtotal,
          order_id: new_order_id
        },
        customer_details: {
          first_name: appData.nama_konsumen,
          email: appData.email,
          phone: appData.telepon
        }
      }
      getSnapToken(requestBody, function(response){
        var response = JSON.parse(response);
        window.snap.pay(response.token);
      });
      //updateOrder(idOrder,idOrderItem,idOrderInfo,new_order_id);
      setOrderId({...orderId,old_order_id: idOrder,new_order_id: new_order_id,order_item_id: idOrderItem,order_info_id: idOrderInfo});
    })
  }
  const updateOrder = (data) => {
    API.postUpdateOrderId({
      id: orderId.old_order_id,
      order_id: orderId.new_order_id}).then((d) => {
      API.postUpdateOrderItemId({
        id: orderId.order_item_id,
        order_id: orderId.new_order_id}).then((c) => {
        API.postUpdateOrderInfo({
          id: orderId.order_info_id,
          order_id: orderId.new_order_id,
          order_data: JSON.stringify(data),
          payment_type: data.payment_type,
          status_code: data.status_code,
          created_date: data.transaction_time}).then((res) => {});
      });
    });
  }
  const intervalPayment = () => {
    API.getMidtransStatus(appData.order_id).then((res) => {
      if(res.data.transaction_status === 'deny' || res.data.transaction_status === 'cancel' || res.data.transaction_status === 'pending'){
        updateOrder(res.data);
      }else if(res.data.transaction_status === 'settlement'){
        API.postUpdateOrderData({
          id: orderId.old_order_id,
          status: 1}).then((response) => {
          API.postUpdateOrderInfo({
            id: orderId.order_info_id,
            order_id: appData.new_order_id,
            order_data: JSON.stringify(res.data),
            payment_type: data.payment_type,
            status_code: data.status_code,
            created_date: data.transaction_time}).then((res) => {});
          ToastsStore.success("Your transaction has been successful");
          getOrders();
          /*setTimeout(function () {
            window.location.reload();
          }, 500);*/
        })
      }
    });
  }
  const getSnapToken = (requestBody, callback) => {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function() {
        if(xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(xmlHttp.responseText);
        }
      }
      API.postMidtransCheckout(JSON.stringify(requestBody)).then((res) => {
        API.getMidtransStatus(appData.order_id).then((response) => {
          if(response.data.status_code === "404" || response.data.token !== null){
            doInterval(true);
            localStorage.setItem('APP_TOKEN_PAYMENT',response.data.token);
            setPaymentUrl(res.data.redirect_url);
            setTimeout(function(){
              window.open(res.data.redirect_url,"_blank");
            },1500);
          }
        });
      });
    }
  const ViewPayment = () => {
    console.log(ids);
    return (
      <>
      <table className="table table-bordered" style={{fontSize: 13}}>
        <tbody>
        <tr>
          <td width="30%">Order ID</td>
          <td width="70%" colSpan="3">{paymentData.order_id}</td>
        </tr>
          {paymentData.payment_type === 'cstore' ? <>
          <tr>
          <td width="30%">Store</td>
          <td width="70%" colSpan="3">{paymentData.store.toUpperCase()}</td>
          </tr>
          <tr>
          <td width="30%">Payment Code</td>
          <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">{paymentData.payment_code}</span></td>
          </tr>
          <tr>
          <td width="30%">Expire Time</td>
          <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">2 Hours</span></td>
          </tr>
          </> : ''}
          {paymentData.payment_type === 'bca_klikpay' ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">BCA Klikpay</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">15 Minutes</span></td>
            </tr>
            <tr>
            <td width="30%">URL Payment</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><a href={"/bca/klikpay/redirect/"+paymentData.transaction_id} target="_blank">Click Here</a></td>
            </tr>
            </> : ''}
          {paymentData.payment_type === 'cimb_clicks' ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">CIMS Clicks</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">15 Minutes</span></td>
            </tr>
            <tr>
            <td width="30%">URL Payment</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><a href={`${props.veritransUrl}/cimb-clicks/request?id=`+paymentData.transaction_id} target="_blank">Click Here</a></td>
            </tr>
            </> : ''}
          {paymentData.payment_type === 'bank_transfer' && paymentData.permata_va_number ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">Bank Transfer (Permata Bank)</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">2 Hours</span></td>
            </tr>
            <tr>
            <td width="30%">Permata VA Number</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}>{paymentData.permata_va_number}</td>
            </tr>
          </> : ''}
          {paymentData.payment_type === 'bank_transfer' && paymentData.va_numbers ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">Bank Transfer</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">2 Hours</span></td>
            </tr>
            {paymentData.va_numbers.map((v,i) => (
              <tr style={{background: '#f4f4f4',fontWeight: 'bolder'}}>
              <td width="30%">Bank {v.bank.toUpperCase()}</td>
              <td width="70%" colSpan="3" style={{fontSize: 14}}>{v.va_number}</td>
              </tr>
            ))}
          </> : ''}
          {paymentData.payment_type === 'echannel' && paymentData.bill_key && paymentData.biller_code ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">Bank Transfer (Mandiri)</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">2 Hours</span></td>
            </tr>
            <tr style={{background: '#f4f4f4',fontWeight: 'bolder'}}>
            <td width="30%">Payment Code</td>
            <td width="70%" style={{fontSize: 14}}><span className="label label-info">{paymentData.bill_key}</span></td>
            <td>Company Code</td>
            <td style={{fontSize: 14}}><span className="label label-info">{paymentData.biller_code}</span></td>
            </tr>
          </> : ''}
          {paymentData.payment_type === 'gopay' ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">Gopay</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">15 Minutes</span></td>
            </tr>
          </> : ''}
          {paymentData.payment_type === 'akulaku' ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">Akulaku</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">15 Minutes</span></td>
            </tr>
            <tr>
            <td width="30%">URL Payment</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><a href={`${props.midtransUrl}v2/akulaku/redirect/`+paymentData.transaction_id} target="_blank">Click Here</a></td>
            </tr>
          </> : ''}
          {paymentData.payment_type === 'danamon_online' ? <>
            <tr>
            <td width="30%">Payment Method</td>
            <td width="70%" colSpan="3">Bank Transfer (Danamon Online)</td>
            </tr>
            <tr>
            <td width="30%">Expire Time</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><span className="label label-info">15 Minutes</span></td>
            </tr>
            <tr>
            <td width="30%">URL Payment</td>
            <td width="70%" colSpan="3" style={{fontSize: 14}}><a href={`${props.veritransUrl}/v2/danamon/online/redirect/`+paymentData.transaction_id} target="_blank">Click Here</a></td>
            </tr>
          </> : ''}
          <tr>
          <td width="30%">Amount</td>
          <td width="70%" style={{fontSize: 14}}><span className="label label-info">{paymentData.gross_amount}</span></td>
          <td width="30%">Currency</td>
          <td width="70%" style={{fontSize: 14}}><span className="label label-info">{paymentData.currency}</span></td>
          </tr>
          <tr>
          <td colSpan="4"><button className="btn btn-info btn-block" onClick={() => checkPayment(ids.orderId,ids.idOrder,ids.idOrderItem,ids.idOrderInfo)}>Check Payment Status</button></td>
          </tr>

        </tbody>
      </table>
      </>
    );
  }
  const checkPayment = (orderId,idOrder,idOrderItem,idOrderInfo) => {
    setIds({orderId,idOrder,idOrderItem,idOrderInfo});
    let status = FnPayment.checkPayment({orderId,idOrder,idOrderItem,idOrderInfo});
    status.then((res) => {
      if(res.transaction_status === 'settlement'){
        ToastsStore.success(res.message);
        setData([]);
        getOrders();
        setTimeout(function(){
          window.location.reload();
        },1500);
      }else if(res.transaction_status === 'pending'){
        setPaymentData(res.data);
        setShowDetail(true);
        ToastsStore.info('Pending, You Need To Complete The Payment');
      }
      else if(res.transaction_status === 'expire'){
        setData([]);
        getOrders();
        ToastsStore.error('Transaction Time Expired');
      }
      else if(res.status_code === '404'){
        let new_order_id = 'ECT-ORD-'+Math.round((new Date()).getTime() / 1000);
        openMidtrans(idOrder,idOrderItem,idOrderInfo,new_order_id,orderId);
        requestCancel(orderId,idOrder,idOrderItem,idOrderInfo);
      }
    });
  }
  const requestCancel = (orderId,idOrder,idOrderItem,idOrderInfo) => {
      API.getMidtransCancel(orderId).then((res) => {
        if(res.data.transaction_status === 'cancel'){
          API.postUpdateOrderData({id: idOrder,status: 6}).then((response) => {
              API.postUpdateOrderInfo({
                id: idOrderInfo,
                order_id: orderId,
                order_data: JSON.stringify(res.data),
                transaction_status: res.data.transaction_status,
                status_code: res.data.status_code,
                created_date: res.data.transaction_time,
                payment_type: res.data.payment_type}).then((res) => {});
            })
            setData([]);
            getOrders();
            ToastsStore.error('Transaction Has Canceled');
            window.location.reload();
        }else{
          ToastsStore.error('Error Canceling Transaction');
        }
    });
  }
  useEffect(() => {
    getOrders();
    if(localStorage.getItem('APP_ORDER_RELOAD') === '0' || localStorage.getItem('APP_ORDER_RELOAD') === null){
      setTimeout(function () {
        localStorage.setItem('APP_ORDER_RELOAD',1);
        window.location.reload();
      }, 100);
    }
  },[])
  return (
    
    <Fragment>
      <Header />
        <div className="row">
          <div className="col-md-2">
            <SidebarAccount />
          </div>
          <div className="col-md-10">

            <div className="card">
              <div className="card-header">
                My Orders History
              </div>
              <div className="card-body">
                <div className="alert alert-warning">
                  <strong>Notice :</strong> Please allowing this site to open popup payment.
                </div>
                <table className="table table-bordered table-striped" style={{textAlign: 'center',fontWeight: '600'}}>
                  <thead>
                  <tr>
                    <th style={{fontSize: 14}}>#</th>
                    <th style={{fontSize: 14}}>Created - Expire Time</th>
                    <th style={{fontSize: 14}}>Amount</th>
                    <th style={{fontSize: 14}}>Items</th>
                    <th style={{fontSize: 14}}>Qty Total</th>
                    <th style={{fontSize: 14}}>Payment Status</th>
                    <th style={{fontSize: 14}}>#</th>
                  </tr>
                  </thead>
                  <tbody style={{fontSize: 13}}>
                    {(() => {
                      if(data.length === 0){
                        return (<tr><td colSpan='7'><center> You Have No Order History</center></td></tr>)
                      }
                    })()}
                    {data.map((v,i) => {
                      if(v.data !== ''){
                        let json = JSON.parse(v.data);
                        return (<tr key={i}>
                          <td>{(i+1)}</td>
                          <td>{json.transaction_time} {json.transaction_status !== 'settlement' ? ' - '+ v.expire_date: '' }</td>
                          <td>Rp. {v.amount}</td>
                          <td>{v.count}</td>
                          <td>{v.qty}</td>
                          <td>{v.status}</td>
                          <td>
                          {(() => {
                            if(json.transaction_status === 'settlement' || v.status === '1'){
                              return (<button className="btn btn-success btn-xs mb-0 mt-1 btn-block" disabled>Finished</button>)
                            }else if(json.transaction_status === 'pending'){
                              return (
                                    <>
                                    <button onClick={() => checkPayment(v.order_id,v.id_order,v.id_order_item,v.id_order_info)} className="btn btn-info btn-xs btn-block mb-0 mt-1">Check Transaction</button>
                                    <button className="btn btn-danger btn-xs btn-block mb-0 mt-1" onClick={() => requestCancel(v.order_id,v.id_order,v.id_order_item,v.id_order_info)}>Cancel</button>
                                    </>
                                    )
                            }else if(json.transaction_status === 'cancel' || json.transaction_status === 'deny'){
                              return (
                                    <>
                                      <button className="btn btn-danger btn-xs btn-block mb-0 mt-1" disabled>Cancel</button>
                                    </>
                                    )
                            }else{
                              return (<button className="btn btn-danger btn-xs btn-block mb-0 mt-1" disabled>Canceled</button>)
                            }
                          })()}
                          <button onClick={() => getOrder(v.order_id)} className="btn btn-xs btn-primary btn-block mb-0 mt-1">Details</button>
                          </td>
                          </tr>)
                        }else{
                          return (<tr key={i}>
                            <td>{(i+1)}</td>
                            <td>{v.start_time} <br/><small>(Will Expire In 24 hours)</small></td>
                            <td>Rp. {v.amount}</td>
                            <td>{v.count}</td>
                            <td>{v.qty}</td>
                            <td>{v.status}</td>
                            <td>
                            <button onClick={() => checkPayment(v.order_id,v.id_order,v.id_order_item,v.id_order_info)} className="btn btn-info btn-xs btn-block mb-0 mt-1">Check Transaction</button>
                            <button className="btn btn-danger btn-xs btn-block mb-0 mt-1" onClick={() => requestCancel(v.order_id,v.id_order,v.id_order_item,v.id_order_info)}>Cancel</button>
                            <button onClick={() => getOrder(v.order_id)} className="btn btn-xs btn-primary btn-block mb-0 mt-1">Details</button>
                            </td>
                            </tr>)
                        }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              ORDER ID - <span id="invoice_id"></span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Invoice id={id} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showDetail}
          onHide={() => setShowDetail(false)}
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
              Payment Detail
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewPayment />
          </Modal.Body>
        </Modal>
        <Modal
          show={showNotice}
          onHide={() => setShowNotice(false)}
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
              Payment Notification
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <blockquote className="blockquote text-center">
              <p className="mb-0">If Window Payment Not Show Automatically. You Can Click Below.</p>
              <footer className="blockquote-footer"><a href={paymentUrl} target="_blank">Click Here.</a></footer>
            </blockquote>
          </Modal.Body>
        </Modal>
        <ToastsContainer store={ToastsStore}/>
    </Fragment>
  )
}
const stateToProp = (state) => {
  return {
    midtransUrl: state.midtransUrl,
    veritransUrl: state.veritransUrl
  }
}
export default connect(stateToProp)(Orders);
