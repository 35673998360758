import React, { useEffect } from 'react';
import logo from './logo.svg';
import Header from './Components/Header';
import Home from './Home/Home';
import View from './View/View';
import Scan from './NavPages/Scan';
import Accs from './NavPages/Accs';
import Parts from './NavPages/PartKnowledge';
import Cart from './NavPages/Cart';
import PartSearch from './NavPages/SearchPage';
import PartKnowlegde from './NavPages/PartKnowledge';
import Login from './Accounts/Login';
import Account from './Accounts/Account';
import Wishlist from './Accounts/Wishlist';
import Bulletin from './NavPages/Bulletin';
import Shipping from './NavPages/Shipping';
import Payment from './NavPages/Payment';
import Orders from './Accounts/Orders';
import MidtransPage from './NavPages/MidtransPage';
import Invoice from './Accounts/Invoice';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";
import RedirectPage from './NavPages/RedirectPage';
import queryString from "query-string"

const App = () => {

    if(localStorage.getItem('APP_SESSION') === null){
      localStorage.setItem('APP_SESSION','{"status": false}');
    }
    const session = JSON.parse(localStorage.getItem('APP_SESSION'));
    
    useEffect(() => {
      if(sessionStorage.getItem('MAIN_URL') !== null)
      {
        window.location.href = sessionStorage.getItem('MAIN_URL');
      }
    },[]);
    
    return (
      <Router>
        <Switch>
          <Route path="/" component={Home} content='Content' exact />
          <Route path="/search" component={Home} content='Content' exact />
          <Route path="/search/:path/:id" component={Home} content='Search' exact  />
          <Route path="/view/:id" component={View} />
          <Route path="/scan" component={Scan} />
          <Route path="/scan/:path/:id" component={Scan} content="Search" />
          <Route path="/parts-knowledge" component={Parts} content="PartsKnowledge" />
          <Route path="/wishlist" component={Wishlist} content="Wishlist" />
          <Route path="/accessories" component={Accs} content="Wishlist" />
          <Route path="/account" component={session['status'] === true ? Account : Login} content="Account" exact />
          <Route path="/midtransPage" component={MidtransPage} content="Midtrans Page" />
          <Route path="/to-page/:model/:part?" component={RedirectPage} content="Redirecting Page" />
          {session['type'] == "221" ? <Route path="/cart" component={Cart} content="Shipping" /> : <Route path="/account" component={Login} content="Login" />}
          {session['type'] == "221" ? <Route path="/payment/:id" component={Payment} content="Payment" /> : <Route path="/account" component={Login} content="Login" />}
          {session['type'] == "221" ? <Route path="/shipping" component={Shipping} content="Shipping" /> : <Route path="/account" component={Login} content="Login" />}
          {session['status'] === 'true' ? <Route path="/orders" component={Orders} content="Login" /> : <Route path="/account" component={Login} content="Login" />}
          {session['type'] == "222" || session['type'] == 223 ? <Route path="/bulletin" component={Bulletin} content="Bulletin" /> : <Redirect to={{pathname: "/account"}} /> }
          {session['type'] == "222" || session['type'] == 223 ? <Route path="/part-search" component={PartSearch} content="Part Search" /> : <Redirect to={{pathname: "/account"}} /> }
          {session['type'] == "221" ? <Route path="/invoice" component={Invoice} content="Invoice" /> : <Route path="/invoice" component={Login} content="Login" />}
        </Switch>
        
      </Router>
      /*<Router>
        <Switch>
          <Route path="/" component={Home} content='Content' exact />
          <Route path="/search" component={Home} content='Content' exact />
          <Route path="/search/:path/:id" component={Home} content='Search' exact  />
          <Route path="/view/:id" component={View} />
          <Route path="/scan" component={Scan} exact />
          <Route path="/scan/:path/:id" component={Scan} content="Search" />
          <Route path="/parts-knowledge" component={Parts} content="PartsKnowledge" />
          <Route path="/wishlist" component={Wishlist} content="Wishlist" />
          <Route path="/accessories" component={Accs} content="Wishlist" />
          <Route path="/midtransPage" component={MidtransPage} content="Midtrans Page" />
          {session['type'] == "221" ? <Route path="/cart" component={Cart} content="Shipping" /> : <Route path="/account" component={Login} content="Login" />}
          {session['type'] == "221" ? <Route path="/payment/:id" component={Payment} content="Payment" /> : <Route path="/account" component={Login} content="Login" />}
          {session['type'] == "221" ? <Route path="/shipping" component={Shipping} content="Shipping" /> : <Route path="/account" component={Login} content="Login" />}
          {session['status'] == true ? <Route path="/account" component={Account} content="Login" /> : <Route path="/account" component={Login} content="Login" />}
          {session['status'] == true ? <Route path="/orders" component={Orders} content="Login" /> : <Route path="/account" component={Login} content="Login" />}
          {session['type'] == "222" || session['type'] == 223 ? <Route path="/bulletin" component={Bulletin} content="Bulletin" /> : <Redirect to={{pathname: "/account"}} /> }
          {session['type'] == "222" || session['type'] == 223 ? <Route path="/part-search" component={PartSearch} content="Part Search" /> : <Redirect to={{pathname: "/account"}} /> }
          {session['type'] == "221" ? <Route path="/invoice" component={Invoice} content="Invoice" /> : <Route path="/invoice" component={Login} content="Login" />}
        </Switch>
      </Router>*/
    );
}
export default App;
