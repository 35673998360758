import React,{Fragment,useState,useEffect} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function SidebarAccount(){
  const options = {
  title: 'Logout',
  message: 'Logout From Your Account?',
  buttons: [
    {
      label: 'Yes',
      onClick: () => {
        localStorage.setItem('APP_SESSION','{"status": false}');
        window.location.reload();
      }
    },
    {
      label: 'No',
      onClick: () => {
        console.log('Failed To Logout')
      }
    }
  ],
};
  return (
    <>
    <ul className="list-group">
      <li className="list-group-item"><a className="nav-link active" href="/account" style={style.smallLink}><i className="fas fa-user"></i> My Account</a></li>
      <li className="list-group-item"><a className="nav-link" href="/orders" style={style.smallLink}><i className="fas fa-receipt"></i> My Orders</a></li>
      <li className="list-group-item"><a className="nav-link" href="/wishlist" style={style.smallLink}><i className="fas fa-heart"></i> My Wishlist</a></li>
      <li className="list-group-item"><a className="nav-link" href="/account" style={style.smallLink} onClick={() => confirmAlert(options)}><i className="fas fa-power-off"></i> Logout</a></li>
    </ul>
    </>
  )
}
const style = {
  smallLink : {
    fontSize: 12,
    fontWeight: 'bolder',
    fontFamily: 'Gotham'
  }
}
