import Axios from 'axios';

//const rootUrl = 'https://air.modena.co.id/ecatalog/api/v1';
//const customUrl = 'https://air.modena.co.id/ecatalog/api/v1';

const rootUrl = process.env.REACT_APP_NODE_ENV !== 'development' ? process.env.REACT_APP_API_PRODUCTION : process.env.REACT_APP_API_DEVELOPMENT;
const customUrl = process.env.REACT_APP_NODE_ENV !== 'development' ? process.env.REACT_APP_API_PRODUCTION : process.env.REACT_APP_API_DEVELOPMENT;

Axios.defaults.baseURL = rootUrl;

const GET = (path) => {
    const promise = new Promise((resolve,reject) => {
        Axios.get(`${path}`).then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        })
    });
    return promise;
}
const POST = (path,data) => {
    const promise = new Promise((resolve,reject) => {
        Axios.post(`${path}`,data).then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        });
    });
    return promise;
}

const ExternalPOST = (path,data) => {
    const promise = new Promise((resolve,reject) => {
        Axios.post(`${path}`,data).then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        });
    });
    return promise;
}

const getBranch = () => GET('/getBranchs');
const getCategory = () => GET('/getCategory');
const getSubcategory = (id) => GET('/getCategory/'+id);
const getModel = (id) => GET('/getModel/'+id);
const getSearch = (data) => GET('/getSearch/'+data);
const getSummary = (data) => GET('/getSummary/'+data);
const getPartItem = (data) => GET('/getPartItem/'+data);
const getModelImage = (data) => GET('/getModelImage/'+data);
const getPartInfo = (data) => GET('/getPartInfo/'+data);
const getPartSubtitution = (data) => GET('/getPartSubtitution/'+data);
const getAppliedItem = (data) => GET('/getAppliedItem/'+data);
const getChangeItem = (data) => GET('/getChangeItem/'+data);
const getBranchItem = (data) => GET('/getBranchItem/'+data);
const getMapContainer = (data) => GET('/getMapContainer/'+data);
const getAllSegment = () => GET('/getSegment');
const getCategoryBySegment = (id) => GET('/getCategory/segment/'+id);
const getPartsOriginal = (id) => GET('/getPartsOriginal/'+id+'/0');
const getModelOriginal = (id) => GET('/getPartsOriginal/0/'+id);
const getPartsSubtitution = (id) => GET('/getPartsSubtitution/'+id);
const getProvinces = () => GET('/getProvince');
const getCity = (id) => GET('/getCity/'+id);
const getKecamatan = (id) => GET('/getKecamatan/'+id);
const getKelurahan = (id) => GET('/getKelurahan/'+id);
const getPostalCode = (id) => GET('/getPostalCode/'+id);
const getCountCart = (id) => GET('/getCountCart/'+id);
const getCountWishlist = (id) => GET('/getCountWishlist/'+id);
const getCartItems = (id) => GET('/getCartItems/'+id);
const getOrderItems = (id) => GET('/getOrderItems/'+id);
const getBulletins = (id) => GET('/getBulletins/'+id);
const getDataWishlist = (id) => GET('/getDataWishlist/'+id);
const getModelPart = (model,part) => GET(`/getModelPart/${model}${part === null ? '' : '/'+part}`)
const getChildItem = (id) => GET('/getChildItems/'+id);
const getAccessories = () => GET('/getAccessories');

const postRegister = (data) => POST('/customerRegister',data);
const postUpdate = (data) => POST('/customerUpdate',data);
const postCheckEmail = (data) => POST('/checkEmail',data);
const postCheckNik = (data) => POST('/checkNik',data);
const postCheckPhone = (data) => POST('/checkPhone',data);
const postLogin = (data) => POST('/login',data);
const postInsertCart = (data) => POST('/postInsertCart',data);
const postInsertWishlist = (data) => POST('/postInsertWishlist',data);
const postRemoveCartItem = (data) => POST('/postRemoveCartItem',data);
const postRemoveWishlistItem = (data) => POST('/deleteWishlist',data);
const postPartSearch = (data) => POST('/postSearch',data);
const postSearchOptions = (data) => POST('/postSearchModel',data);
const postGccs = (data) => ExternalPOST('https://gccs.modena.com/api/v1/transaction/ecatalog/item-request',data);
const getStatusOrder = (data) => POST('/getStatusOrder', data);
const getModelList = (data) => POST('/getModelList', data);
const getScanCode = (data) => POST('/getCodeScan',data);


const API = {
    getBranch,
    getCategory,
    getSubcategory,
    getModel,
    getSearch,
    getSummary,
    getPartItem,
    getModelImage,
    getPartInfo,
    getPartSubtitution,
    getAppliedItem,
    getChangeItem,
    getBranchItem,
    getMapContainer,
    getAllSegment,
    getCategoryBySegment,
    getPartsOriginal,
    getPartsSubtitution,
    getModelOriginal,
    getProvinces,
    getCity,
    getKecamatan,
    getKelurahan,
    getPostalCode,
    getCountCart,
    getCountWishlist,
    getCartItems,
    getOrderItems,
    getBulletins,
    getDataWishlist,
    getModelPart,
    getChildItem,
    getAccessories,
    postRegister,
    postCheckEmail,
    postCheckNik,
    postCheckPhone,
    postLogin,
    postInsertCart,
    postRemoveCartItem,
    postInsertWishlist,
    postUpdate,
    postRemoveWishlistItem,
    postPartSearch,
    postSearchOptions,
    postGccs,
    getStatusOrder,
    getModelList,
    getScanCode
}
export default API;
