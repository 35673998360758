import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Button,Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

const Accs = (props) => {
    const session = JSON.parse(localStorage.APP_SESSION);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [qty, setQty] = useState({
        id: null,
        value: 0
    });
    const [show, setShow] = useState(false);
    const [zoomImage, setZoomImage] = useState(false);
    const [image, setImage] = useState('');
    const [info, setInfo] = useState({
        'title': null,
        'picture': null,
        'info': null
    })
    const getAccessories = () => {
        API.getAccessories().then((res) => {
            setData(res.data);
        });
    }
    const convertPrice = (price) => {
        if(price === null){
            return 0;
        }else{
            let number_string = price.toString();
            let sisa 	= number_string.length % 3;
            let rupiah 	= number_string.substr(0, sisa);
            let ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
            if (ribuan) {
                let separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }
            return rupiah;
        }
    }
    const selectQty = (e) => {
        console.log(qty);
        setQty({
          id: e.target.name,
          value: e.target.value
        });
    }
    const addToCart = (modelId,partId) => {
        if(qty.value < 1){
          ToastsStore.warning("Enter The Number Of Items.");
        }else if(qty.id !== partId){
          ToastsStore.warning("Please Add Quantity For This Item.");
        }else if(qty.value > 0){
          let postData = {
            'konsumen_id': session['service_id'],
            'part_id': partId,
            'model_id': modelId,
            'qty': qty.value
          }
          API.postInsertCart(postData).then((res) => {
              if(res.data.status == true){
                ToastsStore.success(qty.value+' Item Added To Your Basket');
                setQty({
                  id: null,
                  value: 0
                });
                props.setCart(res.data.item);
              }
          }).catch((e) => {
            console.log(e);
          });

        }
    }
    const addToWishlist = (customer_id,id) => {
        let data = {'customer_id': customer_id,'model_id': localStorage.getItem('APP_ID_MODEL'),'part_id': id};
        API.postInsertWishlist(data).then((res) => {
            if(res.data.status == true){
              props.setWishlist(res.data.count);
              ToastsStore.success(res.data.msg);
            }else{
              ToastsStore.error(res.data.msg);
            }
        });
    }
    const onchange = (v) => [
        setFilter(v.target.value)
    ]
    const infoAccessories = (title,image,info) => {
        setShow(true);
        setInfo({
            title: title,
            info: info,
            picture: image
        })
    }
    const popupImage = (v) => {
        setImage(v);
        setZoomImage(true);
      }
    useEffect(() => {
        props.title('ACCESSORIS');
        getAccessories();
    },[]);
    return (
        <>
            <Header line="line-title" />
            <div className="ml-3 mr-1" style={{width: '100% !important',paddingTop: 40}}>
                <div className="row">
                    <div className="col-md-4 d-flex justify-content-end mb-2 pl-0">
                                    <div className="input-group mb-0">
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary custom-button" type="submit" id="button-addon2" disabled>
                                        <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                    <input type="text" className="form-control custom-input" placeholder="Search Accs Code / Name" name="partName" onChange={(text) => onchange(text)} />
                                    </div>
                    </div>
                    <div className="col-md-12 pl-0">
                    <div className="block-search">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr style={{background: '#999',color: '#fff'}}>
                                    <th width="10%">#</th>
                                    <th width="10%">Accs Code</th>
                                    <th width="20%">Accs Name</th>
                                    <th width="15%">Category</th>
                                    <th width="5%">Price</th>
                                    <th width="5%">Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.filter((fnc) => {
                                    if(filter === '')
                                        return fnc
                                    else if(filter !== ''){
                                      return (fnc.title||'').toLowerCase().indexOf(filter) !== -1 || (fnc.id||'').indexOf(filter) !== -1
                                    }
                                    }).map((v,i) => {
                                    return (
                                        <tr key={i}>
                                            <td><img src={v.picture} onClick={() => popupImage(v.picture)} width="50%" style={{border: '1px solid #ccc'}} onError={e => { e.currentTarget.src = props.csmsUrl+v.picture; }} /></td>
                                            <td>{v.id}</td>
                                            <td>{v.title}</td>
                                            <td>{v.category}</td>
                                            <td>Rp. {v.sellprice}</td>
                                            <td>
                                                <select onChange={selectQty} name={v.id} value={qty.id != v.id ? 0 : qty.value} style={{width: '100%'}}>
                                                {(() => {
                                                    if(session['type'] == '222' || session['type'] == '223'){
                                                    return (<option>{parseFloat(v.stock)}</option>);
                                                    }else{
                                                    const options = [];
                                                    if(v.stock === '.0000'){
                                                        options.push(<option value='0'>0</option>);
                                                    }
                                                    for(let i=1;i <= parseFloat(v.stock);i++){
                                                        options.push(<option value={i} key={i}>{i}</option>);
                                                    }
                                                    return options;
                                                    
                                                    }
                                                })()}
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
            <Modal
              show={show}
              onHide={() => setShow(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  {info.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row">
                      <div className="col-md-4">
                        <img src={info.picture} width="100%" style={{border: '1px solid #ccc'}}  onError={e => { e.currentTarget.src = props.csmsUrl+info.picture; }} />
                      </div>
                      <div className="col-md-8" style={{fontSize: 13,lineHeight: '27px'}}>
                        {info.info === '' || info.info === null ? 'There Is No Information For This Accessories' : info.info}
                      </div>
                  </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={zoomImage}
              onHide={() => setZoomImage(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Part Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center><img src={image} width="100%" onError={e => { e.currentTarget.src = props.csmsUrl+image; }} /></center>
              </Modal.Body>
            </Modal>
            <ToastsContainer store={ToastsStore}/>
        </>
    )
}
const style = {
    regiterLabel: {
      fontSize: '13px',
      fontWeight: 600
    }
}
const mapDispatch = (dispatch) => {
    return {
        title: (title) => dispatch({type: "GET_TITLE",title: title}),
        setCart: (qty) => dispatch({type: 'SET_CART',cart: qty}),
        setWishlist: (items) => dispatch({type: 'SET_WISHLIST',wishlistItems: items}),
        setListLoading: (status) => dispatch({type: 'SET_LIST_LOADING',listLoading: status})
    }
}
const stateToProps = (state) => {
  return{
      url: state.rootUrl,
      csmsUrl: state.csmsURL
  }
}
export default connect(stateToProps,mapDispatch)(Accs);