import React,{Component,Fragment,useState,useEffect} from 'react';
import Header from './../Components/Header';
import Image from './Image';
import List from './List';
import API from './../Services/Api';
import {connect} from 'react-redux';
import {
    useParams
} from "react-router-dom";

const View = (props) => {
    const [image, setImage] = useState([]);
    const param = useParams();
    const [filter, setFilter] = useState(localStorage.APP_PART_FILTER);
    const [filterChar, setFilterChar] = useState('');
    const [loading, setLoading] = useState(true);

    const getData = () => {
        API.getModelImage(param.id).then((res) => {
            setImage(res.data.data);
            props.setModelImage(res.data.data.modelImage);
        });
    }
    const filterChange = (e) => {
        localStorage.setItem('APP_PART_FILTER',e.target.value);
        window.location.reload();
    }
    const filterText = (e) => {
        setFilterChar(e.target.value);
    }
    const handlePaste = (e) => {
        setFilterChar(e.clipboardData.getData('Text'));
    }
    useEffect(() => {
        getData();
        document.getElementById("main-menu").disabled = true;
    }, []);
    document.title = image.description + ' | E-Catalog Modena';
    return (
            <Fragment>
                <Header />
                <div className="container-custom-detail">
                    <div className="row row-moz" style={{padding: 0,display: 'block ruby'}}>
                        <div className="col-md-6 mb-2">
                            <div className="input-group mt-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text model-custom-btn" id="basic-addon3">E-Catalog Model</span>
                            </div>
                            <input type="text" className="form-control model-custom-name" id="model_name" disabled={true} value={image.name +' / '+ image.description} style={{backgroundColor: '#fff'}} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row" style={{padding: '0'}}>
                            <div className="col-md-6 pl-3">
                                <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text lot-custom-btn" id="basic-addon3">Start Lot</span>
                                    </div>
                                    <input type="text" className="form-control model-custom-name" disabled={true} value={image.start_lot} style={{backgroundColor: '#fff'}} />
                                </div>
                            </div>
                            <div className="col-md-6 pl-3">
                                <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text lot-custom-btn" id="basic-addon3">Stop Lot</span>
                                    </div>
                                    <input type="text" className="form-control model-custom-name" disabled={true} value={image.finish_lot} style={{backgroundColor: '#fff'}} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-1">
                                </div>
                                <div className="input-group mb-1" style={{marginTop: '6px'}}>
                                  <div className="input-group-prepend">
                                      <span className="input-group-text lot-custom-btn" id="basic-addon3">Filter By :</span>
                                  </div>
                                    <select className="form-control model-custom-name" onChange={filterChange}>
                                        <option value="0" selected={localStorage.getItem('APP_PART_FILTER') == '0' ? true:false}>Select Type Filter</option>
                                        <option value="F" selected={localStorage.getItem('APP_PART_FILTER') == 'F' ? true:false}>- Function</option>
                                        <option value="T" selected={localStorage.getItem('APP_PART_FILTER') == 'T' ? true:false}>- Support</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-1">
                                </div>
                                <div class="input-group mb-3" style={{marginTop: '6px'}}>
                                <input type="text" class="form-control model-custom-name" placeholder="Search Part Name / Exp.Number" onChange={text => filterText(text)} onPaste={text => handlePaste(text)} />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                    <Image image={image.image} map_id={image.map_id} id={param.id} desc={image.description}  />
                    <List filter={filter} filterChar={filterChar} />
                    
                    </div>
                </div>
            </Fragment>
    )
}
const dispatchImage = (dispatch) =>{
    return{
        setFilterPart: (part) => dispatch({type: 'SET_FILTER_PART',filterPart: part}),
        setModelImage: (image) => dispatch({type: 'SET_MODEL_IMAGE',image:image}),
        setfilterItem: (filter) => dispatch({type: 'SET_FILTER',filterItem: filter})
    }
}
export default connect(null,dispatchImage)(View);
