import React,{Component,Fragment} from 'react';
import API from './../Services/Api';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';

class Subcategory extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: [],
            model: [],
            loading: true,
            open: {
                status: 'none',
                id: 0
            }
        };
    }
    getSubcategory = () => {
        API.getSubcategory(this.props.id).then((res) => {
            this.setState({
                data: res.data.data
            });
        });
    }
    getModel = (id) => {
        API.getModel(id).then((res) => {
            this.setState({
                model: res.data.data,
                loading: false
            });
        });
        this.props.getData(id);
    }
    isOpen = (id,my_id) => {
        this.setState({
            open: {
                status: 'block',
                id: id
            }
        });
        API.getSummary(my_id).then((res) => {
            console.log(res.data.data);
            this.props.dataModal(res.data.data);
        });
        this.props.openModal(true);
        this.props.myid(my_id);
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }
    componentDidMount(){
        this.getSubcategory();
    }
    render(){
        return(
            <Fragment>
                    {this.state.data.map((v,key) => (
                    <div className="card-custom" key={key}>
                        <div className="btn-group btn-group-collapse" style={{width: '100%'}}>
                        <button className="custom-accordion-btn" onClick={() => this.getModel(v.sc_id)}>{v.name}</button>
                            <div className="dropdown-toggle custom-icon-dropdown">
                            <span className="caret"></span>
                            </div>
                        </div>
                        <div className="collapse-custom" id="collapseContent" style={{display : this.props.open.id == v.sc_id ? this.props.open.open : 'none'}}>
                            <div className="card card-body" id="one">
                            <table className="table table-bordered">
                                <tbody>
                                    {this.state.loading ? <tr><td><center><Loader type="ThreeDots" color="#333" height={15} width={25} /></center></td></tr> : ''}
                                    {this.state.model.map((p,k) => (
                                        <tr key={k} onClick={() => this.isOpen(p.m_id,p.year_id)}>
                                        <td>{p.name}</td>
                                        <td width="45%">{p.year} - {p.lot}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    ))}
            
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        data: state.dataModal
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        myid: (id) => dispatch({type:'SEND_MYPID',myp_id: id}),
        sendId: (id) => dispatch({type: 'ADD_DATA',id: id}),
        openModal: (status) => dispatch({type: 'OPEN_MODAL',modal: status}),
        dataModal: (data) => dispatch({type: 'DATA_MODAL',dataModal: data})
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Subcategory);