import React,{Fragment,useState,useEffect} from 'react';
import API from '../../Services/Api';
import Scanner from '../../Components/Scanner';

const Blank = (props) => {
    const [onScan, setOnScan] = useState(true);
    const [result, setResult] = useState(null);

    const onDetected = result => {
        setResult(result);
        if(result !== null){
            const searchCode = API.getScanCode({
                'codeScan': result
            });
            searchCode.then((res) => {
                if(res.data.data[0].total_code != 0){
                    setOnScan(false);
                    window.location.href = '/search/byPartNumber/'+result;
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    };
    const handleError = err => {
        console.log(err);
    }
    const handleScan = data => {
        props.partNumber(data);
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-6 mt-3">
                    <div className="block-search">
                        <div class="alert alert-info">Point the camera at the Part Barcode as close as possible </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    {onScan ? <Scanner onDetected={onDetected} /> : null}
                </div>
            </div>
        </Fragment>
    )
}
export default Blank;