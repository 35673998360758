import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';

const Invoice = (props) => {
  const [paymentStatus, setPaymentStatus] = useState('UNPAID');
  const [order, setOrder] = useState([]);
  const [data, setData] = useState({data: []});
  let session = JSON.parse(localStorage.getItem('APP_SESSION'));
  var appData = JSON.parse(window.localStorage.getItem('APP_PAYMENT_DATA'));

  const getOrder = () => {
    document.getElementById("invoice_id").innerHTML = props.id;
    API.getOrderId(props.id,session.service_id).then((res) => {
      setOrder(res.data.data);
      if(res.data.data.transaction_status === 'settlement' || res.data.transaction_status === 'challenge'){
        setPaymentStatus('PAID');
      }
      setData(res.data.data.items);
      })
  }
  useEffect(() => {
    getOrder();
  },[])
  return (
    <Fragment>
      <div class="invoice-box">
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr class="top">
                <td colspan="2">
                    <table>
                        <tr>
                            <td className="title">
                                <img src="img/logo.png" style={{'width':'50%','maxWidth':'200px'}} />
                            </td>

                            <td>
                              <small><strong>Order ID: {props.id}</strong></small><br />
                              {(() => {
                                if(paymentStatus === 'PAID'){
                                  return (<span style={{fontSize: 14,color: 'green'}}>STATUS: PAID</span>)
                                }else{
                                  return (<span style={{fontSize: 14,color: 'red'}}>STATUS: UNPAID</span>)
                                }
                              })()}

                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
          </thead>
          <tbody>
            <tr className="information">
                <td colSpan="2">
                    <table>
                      <tbody>
                        <tr>
                            <td width="60%">
                                {order.nama_konsumen}<br />
                                <small>{order.alamat}<br />
                                {order.kota} - {order.provinsi}</small>
                            </td>

                            <td>
                                <small>Modena Indonesia.<br />
                                Jln. Prof. Dr. Satrio C-4 No. 13<br/>
                                Jakarta 12950, Indonesia</small>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </td>
            </tr>

            <tr className="heading">
                <td>
                    Item
                </td>

                <td>
                    Price
                </td>
            </tr>

            {data.data.map((v,i) => {
              return (<tr className="item last" key={i}><td><span style={{fontSize: 13,textTransform: 'Uppercase'}}>
              {v.part_name} - {v.part_code}</span> x <span style={{fontSize: 13}}>{v.kuantitas}pcs</span></td>
            <td><span style={{fontSize: 13}}>Rp.{v.harga}</span></td></tr>)
            })}


            <tr className="heading" style={{marginTop: 20}}>
                <td>
                    Shipping Detail
                </td>

                <td>
                    Cost
                </td>
            </tr>

            <tr className="details">
                <td>
                    <span style={{fontSize: 13,textTransform: 'Uppercase'}}>{order.shipping_name} x {order.shipping_weight}g</span>
                </td>

                <td>
                    <span style={{fontSize: 13}}>Rp.{order.shipping_value}</span>
                </td>
            </tr>

            <tr className="total">
                <td><span style={{fontSize: 15,fontWeight: 'bold'}}>Total Price</span></td>

                <td>
                   <span style={{fontSize: 15}}>Rp.{order.subtotal}</span>
                </td>
            </tr>
          </tbody>
        </table>
    </div>
  </Fragment>
  )
}
export default Invoice;
