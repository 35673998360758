const initialState = {
    rootUrl: 'https://apiecatalog.modena.com/',
    apiUrl: 'https://apiecatalog.modena.com/',
    csmsURL: 'https://csms.sap.modena.com/',
    oldCsms: 'http://csms.modena.co.id/root/',
    oldPart: 'https://csms.sap.modena.com/photos/part_old/',
    newPart: 'https://csms.sap.modena.com/photos/part/',
    //rootUrl: 'http://localhost:3000/',
    //apiUrl: 'http://192.168.0.6:8080/ecatalog-api/api/',
    //midtransUrl: 'https://api.sandbox.midtrans.com/',
    //veritransUrl: 'https://api.sandbox.veritrans.co.id/',
    id: null,
    model: null,
    modal: false,
    myp_id: '',
    title: '',
    listLoading: true,
    cart: 0,
    dataModal: [],
    cartItems: [],
    wishlistItems: 0,
    filterItem: 0,
    filterPart: ''
};
const Reducer = (state = initialState,action) => {
    switch(action.type){
        case 'ADD_DATA':
            return {
                id: action.id,
                model: action.model
            }
        break;
        case 'SEND_MYPID':
            return {
                ...state,
                myp_id: action.myp_id
            }
        break;
        case 'OPEN_MODAL':
            return {
                ...state,
                modal: action.modal
            }
        break;
        case 'DATA_MODAL':
            return {
                ...state,
                dataModal: action.dataModal
            }
        break;
        case 'GET_TITLE':
            return {
                ...state,
                title: action.title
            }
        break;
        case 'SET_MODEL_IMAGE':
            return {
                ...state,
                modelImage: action.image
            }
        break;
        case 'SET_LIST_LOADING':
            return {
                ...state,
                listLoading: action.listLoading
            }
        break;
        case 'SET_CART':
          return {
            ...state,
            cart: action.cart
          }
        break;
        case 'SET_CART_ITEMS':
          return {
            ...state,
            cartItems: action.cartItems
          }
        break;
        case 'SET_WISHLIST':
          return {
            ...state,
            wishlistItems: action.wishlistItems
          }
        break;
        case 'SET_FILTER':
          return {
            ...state,
            filterItem: action.filterItem
          }
        break;
        case 'SET_FILTER_PART':
            return {
                ...state,
                filterPart: action.filterPart
            }
        break;
    }
    return state;
}
export default Reducer;
