import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
    useHistory,
    useParams
} from "react-router-dom";

const Search = (props) => {
    const [data, setData] = useState({data: []});
    const [branchs, setBranchs] = useState({data: []});
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({data: []});
    const [openModal, setOpenModal] = useState(false);

    let param = useParams();
    localStorage.setItem('APP_RELOAD',0);
    const action = () => {
        setLoading(true);
        if(param.path == 'byModel'){
            let id = param.id.split('+').join('_');
            API.getSearch('model/'+id).then((res) => {
                setLoading(false);
                setData({
                    data: res.data.data
                })
            });
        }else if(param.path == 'byPartNumber'){
            let id = param.id.split('+').join('_');
            API.getSearch('part_number/'+id).then((res) => {
                setLoading(false);
                setData({
                    data: res.data.data
                })
            });
        }else if(param.path == 'byPartName'){
            let id = param.id.split('+').join('_');
            API.getSearch('part_name/'+id).then((res) => {
                setLoading(false);
                setData({
                    data: res.data.data
                })
            });
        }else if(param.path == 'byBarcode'){
            let id = atob(param.id);
            API.getSearch('barcode/'+id).then((res) => {
                setLoading(false);
                setData({
                    data: res.data.data
                })
            });
        }else if(param.path == 'byModel'){
            let id = param.id;
            API.getSearch('model_number/'+id).then((res) => {
                setLoading(false);
                setData({
                    data: res.data.data
                })
            });
        }
    }
    const GetBranch = () => {
        API.getBranch().then((res) => {
            setBranchs({data: res.data.data});
        });
    }
    const GetSummary = (id) => {
        setOpenModal(true);
        API.getSummary(id).then((res) => {
            setModal({data: res.data.data});
        });
    }
    const Redirect = (mypid) => {
        window.location.href = '/view/'+mypid;
    }
    const handleClose = () => {
        setOpenModal(false);
    }

    useEffect(() => {
        action();
        GetBranch();

        if(sessionStorage.getItem('MAIN_URL') !== null)
        {
            window.location.href = sessionStorage.getItem('MAIN_URL');
        }
    },[]);
    document.title = 'E-Catalog Modena | Pencarian';
    document.keyword = 'modena,modena indonesia,modena ecatalog,modena.co.id,air.modena.co.id';
    document.description = 'E-Catalog Modena';
    return(
        <Fragment>
            <div className="container-custom mt-2">
                <div className="row">
                    {branchs.data.map((v,key) => (
                        <div className="col-md-6" key={key}>
                            <div className="block-one">
                                {v.name}
                            </div>
                            <div className="block-search">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th width="10%">#</th>
                                            <th width="45%">Subcategory</th>
                                            <th width="25%">Model Name</th>
                                            <th width="10%">Year</th>
                                            <th width="10%">Lot</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? <tr><td colSpan="4"><center><Loader type="ThreeDots" color="orange" height={30} width={40} /> </center></td></tr> : '' }
                                        {data.data.map((p,k) => (
                                            p.segment_id === v.s_id ? <tr key={k} onClick={(e) => GetSummary(p.model_year_id)}><td>{p.category_name}</td><td>{p.sub_category_name}</td><td>{p.model_name}</td><td>{p.year}</td><td>{p.lot}</td></tr> : ''
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal show={openModal} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{modal.data.length === 0 ? '-' : modal.data[0].model_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                    {modal.data.map((v,i) => (
                                <div className={modal.data.length === 1 ? 'col-md-12' : 'col-md-6'}>
                                    <center>
                                    <a href={`/view/${v.model_year_part_id}`} target="_blank">
                                        <img className="img-Modal" src={props.csmsUrl+v.image} width="100%" />
                                        <h6 style={{border: '1px solid #ccc',backgroundColor: '#eee',padding: 5,marginBottom: 10}}>{v.part}</h6>
                                    </a>
                                    </center>
                                </div>   
                    ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {modal.data.length === 0 ? '-' : modal.data[0].model_description}
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
const stateToProps = (state) => {
    return {
        modalData: state.dataModal,
        modal: state.modal,
        url: state.rootUrl,
        csmsUrl: state.csmsURL
    }
}
export default connect(stateToProps)(Search);
