import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import queryString from "query-string"
import {
    useParams,
    useHistory,
} from "react-router-dom";

export default function RedirectPage() {
  const [message, setMessage] = useState('Please wait, system will check and redirecting...');  
  const params = useParams();
  const history = useHistory();

  const getUrl = (params) => {
    const model = params.model;
    const part = params.part;
    const getData = API.getModelPart(model,part === undefined ? null : part);
    getData.then(res => {
        if(res.data.code === 404){
          if(sessionStorage.getItem('APP_GCCS')){
            alert('Model / Item Not Found at Ecatalog');
            window.opener = null;
            window.open('', '_self');
            window.close(); 
            window.location.href = '/';
            sessionStorage.clear();
            localStorage.removeItem('APP_SESSION')
          }else{
            ToastsStore.error('Model Or Part Not Found.');
            setMessage('You Will Redirect To Homepage.');
            setTimeout(() => {
              window.location.href = "/";
            },3000);
          }
        }else{
          if(res.data.url === 'https://ecatalog.modena.com/view/' || res.data.length === 0){
            alert('Model / Item Not Found at Ecatalog');
            window.opener = null;
            window.open('', '_self');
            window.close(); 
            window.location.href = "/";
          }else{
            if(sessionStorage.getItem('APP_GCCS')){
              sessionStorage.setItem('MAIN_URL', res.data.url);
            }
            window.location.href = res.data.url;
          }
        }
    })
  }  

  function getQueryVariable(variable)
  {
          var query = window.location.search.substring(1);
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
                      var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
          }
          return(false);
  }

  useEffect(() => {
    //const queryParams = queryString.extract(window.location.href);
    try {
      const fullUrl = window.location.href;
      const search = fullUrl.split("?")[1].toString();
      const queryParams = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })

      if(queryParams.key === "47F23657EFD41"){
        let model = queryParams.model;
        let tid = queryParams.service;
        let woid = queryParams.workorder;
        sessionStorage.setItem('APP_GCCS',true);
        sessionStorage.setItem('GCCS_DATA',JSON.stringify({"model": model,"serviceId": tid,"workorder": woid}));
        let json = JSON.stringify( {"status": true,"service_id":tid,"type":"224","gudang_id":"GDGSTG"});
        localStorage.setItem('APP_SESSION',json);
      }
      getUrl(params);
    } catch (error) {
      getUrl(params);
    }
   // getUrl(params);
  });  
  return (
    <>
    <div>{message}</div>
    <ToastsContainer store={ToastsStore}/>
    </>
  )
}
