import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ToastsContainer, ToastsStore} from 'react-toasts';

function Cart(props){
    const [data, setData] = useState({data: []});
    const [amount, setAmount] = useState(0);
    const [id, setId] = useState(0);

    const getItems = () => {
      let session = JSON.parse(localStorage.getItem('APP_SESSION'));
      API.getCartItems(session['service_id']).then((res) => {
        setData({data: res.data.data});
        setAmount(res.data.totalPrices);
      })
    }
    const removeItems = (vId) => {
      setId(vId);
      if(vId != 0){
        let dataCart = {'cart_id': vId};
        if(window.confirm('Remove This Item From Shopping Cart?') == true){
          API.postRemoveCartItem(dataCart).then((res) => {
            if(res.data.status == true){
                props.setCart(res.data.qty);
                window.location.reload();
            }else{
                ToastsStore.error('Something Error, Please Contact Web Administrator.');
            }
          });
        }
      }
    }

    useEffect(() => {
      getItems();
    },[]);
    return (
        <Fragment>
          <Header />
          <div className="row">
            <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="alert alert-primary" style={{fontSize: 13,fontWeight:'Gotham',fontWeight:600}}>Your Items In Cart</div>
                    <table className="table table-bordered table-striped table-hover" style={style.regiterLabel}>
                      <tr>
                        <th>No.</th>
                        <th>Part Name</th>
                        <th>Model / Category</th>
                        <th><center>Qty</center></th>
                        <th>Price</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                      <tbody id="itemsList">
                      {(() => {
                        if(props.cart == 0){
                          return (
                            <>
                                <tr>
                                  <td colSpan="7"><center>No product yet in your cart.</center></td>
                                </tr>
                              <tr>
                              </tr>
                            </>
                          )
                        }
                      })()}
                      {data.data.map((v,i) => {
                        const model = v.model_id;
                        console.log(model.length);
                        return (
                            <tr key={i}>
                              <td><center>{(i+1)}</center></td>
                              <td>{v.part_name}</td>
                              <td>
                                {v.model_name}
                              </td>
                              <td><center>{v.qty}</center></td>
                              <td><center>{v.price} x {v.qty}</center></td>
                              <td><center>{v.total}</center></td>
                              <td><center><button onClick={() => removeItems(v.cart_id)} className="btn btn-transparent"><i className="fas fa-trash-alt"></i></button></center></td>
                            </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <table className="table table-striped table-bordered" style={{fontSize:13,fontFamily: 'Gotham',fontWeight: 600}}>
                    <tr>
                      <td colSpan="3">Order Summary</td>
                    </tr>
                    <tr>
                      <td width="35%">Your Items</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">{props.cart} Items</td>
                    </tr>
                    <tr>
                      <td width="35%">Products</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">{data.data.length} Products</td>
                    </tr>
                    <tr>
                      <td width="35%">Total Prices</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">Rp. {amount}</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <a href="#/shipping" className={data.data.length === 0 ? "btn btn-sm btn-info btn-block disabled":"btn btn-sm btn-info btn-block"}>Shipping Method</a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
    )
}
const style = {
  regiterLabel: {
    fontSize: '13px',
    fontWeight: 600
  }
}
const mapStateToProps = (state) => {
  return{
    cart: state.cart
  }
}
const dispatchToProps = (dispatch) => {
    return {
        setCart: (qty) => dispatch({type: 'SET_CART',cart: qty})
    }
}
export default connect(mapStateToProps,dispatchToProps)(Cart);
