import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Modal,Button } from 'react-bootstrap';
import ReactInterval from 'react-interval';
import {
    Redirect,
    useParams
} from "react-router-dom";
import './Payment.css';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

function Payment(props){
    const [data, setData] = useState({data: []});
    const [order, setOrder] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [account, setAccount] = useState([]);
    const [amount, setAmount] = useState(0);
    const [address, setAddress] = useState({data: []});
    const [shippingCost, setShippingCost] = useState(0);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState('UNPAID');
    const [loading, setLoading] = useState(false);
    const [interval, doInterval] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('');
    const [showNotice, setShowNotice] = useState(false);
    const [modalPayment, setModal] = useState({
      url: null,
      show: false
    })
    const param = useParams();
    const countOrder = 0;
    let session = JSON.parse(localStorage.getItem('APP_SESSION'));
    let new_order_id = 'ECT-ORD-'+Math.round((new Date()).getTime() / 1000);
    var appData = JSON.parse(window.localStorage.getItem('APP_PAYMENT_DATA'));

    const getAddress = () => {
      API.getAddress(session['service_id']).then((res) => {
          setAddress(res.data);
      });
    }
    const getAccount = () => {
      let konsumen_id = JSON.parse(localStorage.getItem('APP_SESSION')).service_id;
      API.getDataKonsumen(konsumen_id).then((res) => {
        setAccount(res.data.data[0]);
      });
    }
    const postOrderInfoBlank = () => {
      let konsumen_id = JSON.parse(localStorage.getItem('APP_SESSION')).service_id;
      API.postOrderInfoBlank({'konsumen_id': konsumen_id,'order_id': appData.order_id}).then((res) => {
        console.log('Order Info Created');
      });
    }

    const getOrder = () => {
      API.getOrderId(param.id,session.service_id).then((res) => {
          if(res.data.count !== '0'){
            setOrder(res.data.data);

            API.getOrderItems(param.id).then((response) => {
              setData(response.data);
              API.getMidtransStatus(appData.order_id).then((response) => {
                if(response.data.status_code === "404"){
                  openMidtrans(appData.order_id);
                }else if(response.data.status_code === '201' && response.data.status_code === '200'){
                  /*let paymentData = {email: appData.email,nama_konsumen: appData.nama_konsumen,order_id: new_order_id,telepon:appData.telepon,total: appData.total};
                  localStorage.setItem('APP_PAYMENT_DATA',JSON.stringify(paymentData));*/
                  localStorage.setItem('APP_ORDER_RELOAD',0);
                  window.location.href = '#/orders';
                  /*API.postUpdateOrderId({f_key: res.data.data.f_key,order_id: new_order_id}).then((d) => {
                    API.postUpdateOrderItemId({f_key: res.data.data.f_key,order_id: new_order_id}).then((c) => {
                      window.location.href = '#/account/';
                    });
                  });*/
                }
              });

            });
          }
      });

    }
    const checkPayment = () => {
      setDisabled(true);
      API.getMidtransStatus(param.id).then((res) => {
        console.log(res.data);
        setLoading(false);
        if(res.data.status_code === '200'){
          API.postUpdateOrderData({order_id: param.id,status: 1}).then((res) => {});
        }
        if(res.data.status_code === '201' || res.data.status_code === '200'){
          console.log(res.data.transaction_status);
          API.getDeleteCart(session.service_id).then((res) => {
            console.log('Removing Cart Items');
            props.setCart(0);
          });
          let data = {'konsumen_id': session.service_id,
            'order_id': appData.order_id,
            'data': JSON.stringify(res.data),
            'payment_type': res.data.payment_type,
            'transaction_status': res.data.transaction_status,
            'status_code': res.data.status_code,
            'created_date': res.data.transaction_time};
          API.postOrderInfo(data).then((res) => {
            if(res.data.status === 'true'){
              localStorage.setItem('APP_ORDER_RELOAD',0);
              window.location.href = '#/orders';
              setDisabled(false);
            }
          });
        }else if(res.data.transaction_status === "expire"){
          let data = {'konsumen_id': session.service_id,
            'order_id': appData.order_id,
            'data': JSON.stringify(res.data),
            'payment_type': res.data.payment_type,
            'transaction_status': res.data.transaction_status,
            'status_code': res.data.status_code,
            'created_date': res.data.transaction_time};
          API.postOrderInfo(data).then((res) => {
            if(res.data.status === 'true'){
              ToastsStore.success("Your Transaction Has Been Expired.");
              window.location.href = '#/orders';
            }
          });
        }else if(res.data.transaction_status === "cancel"){
          let data = {'konsumen_id': session.service_id,
            'order_id': appData.order_id,
            'data': JSON.stringify(res.data),
            'payment_type': res.data.payment_type,
            'transaction_status': res.data.transaction_status,
            'status_code': res.data.status_code,
            'created_date': res.data.transaction_time};
          API.postOrderInfo(data).then((res) => {
            if(res.data.status === 'true'){
              ToastsStore.success("Your Transaction Has  Been Canceled.");
              window.location.href = '#/orders';
            }
          });
        }
      });
    }
    const openMidtrans = (order_id) => {
      var requestBody =
      {
        transaction_details: {
          gross_amount: appData.total,
          order_id: order_id
        },
        customer_details: {
          first_name: appData.nama_konsumen,
          last_name: "",
          email: appData.email,
          phone: appData.telepon
        }
      }
      getSnapToken(requestBody, function(response){
        var response = JSON.parse(response);
        window.snap.pay(response.token);
      })
    }
    const getSnapToken = (requestBody, callback) => {
        var xmlHttp = new XMLHttpRequest();
  	    xmlHttp.onreadystatechange = function() {
  	      if(xmlHttp.readyState == 4 && xmlHttp.status == 200) {
  	        callback(xmlHttp.responseText);
  	      }
  	    }
        API.postMidtransCheckout(JSON.stringify(requestBody)).then((res) => {
          API.getMidtransStatus(appData.order_id).then((response) => {

            if(response.data.status_code === "404"){
              setPaymentUrl(res.data.redirect_url);
              setShowNotice(true);
              window.open(res.data.redirect_url,"");
              localStorage.setItem('APP_TOKEN_PAYMENT',response.data.token);
            }else if(response.data.status_code === '200' || response.data.status_code === '407' || response.data.status_code === '202' || response.data.status_code === '201'){
              localStorage.setItem('APP_ORDER_RELOAD',0);
              window.location.href = '#/orders';
            }
            else{
              API.getDeleteCart(session.service_id).then((res) => {
                console.log('Removing Cart Items');
                props.setCart(0);
              });
            }
          });
        });
      }
    const checkCart = () => {
      checkPayment();
      API.getMidtransStatus(param.id).then((res) => {
        if(res.data.status_code === '201' || res.data.status_code === '200'){
          API.getDeleteCart(session.service_id).then((res) => {
            props.setCart(0);
          });
        }
      });
    }

    useEffect(() => {
      getAddress();
      getAccount();
      getOrder();
      checkPayment();
      doInterval(true);
      postOrderInfoBlank();
      if(param.id == null){
        window.location.href = '#/'
      }
    },[]);
    return (
        <Fragment>
          {loading === true ? <Loader fullPage loading /> : ''}
          <Header />
          <ReactInterval timeout={3000} enabled={interval}
                callback={() => checkCart()} />
          <div className="row">
            <div className="col-lg-8">
              <div className="alert alert-info">
                <strong>Notice :</strong> Please allowing this site to open popup payment.
              </div>
              <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr className="top">
                        <td colSpan="2">
                            <table>
                                <tr>
                                    <td className="title">
                                        <img src="img/logo.png" style={{'width':'50%','maxWidth':'200px'}} />
                                    </td>

                                    <td>
                                      <small><strong>Invoice: -</strong></small><br />
                                      {(() => {
                                        if(paymentStatus == 'PAID'){
                                          return (<span style={{fontSize: 14,color: 'green'}}>STATUS: PAID</span>)
                                        }else{
                                          return (<span style={{fontSize: 14,color: 'red'}}>STATUS: UNPAID</span>)
                                        }
                                      })()}

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="information">
                        <td colSpan="2">
                            <table>
                              <tbody>
                                <tr>
                                    <td width="60%">
                                        {account.nama_konsumen}<br />
                                        <small>{account.alamat}<br />
                                        {account.city_name} - {account.province_name}</small>
                                    </td>

                                    <td>
                                        <small>Modena Indonesia.<br />
                                        Jln. Prof. Dr. Satrio C-4 No. 13<br/>
                                        Jakarta 12950, Indonesia</small>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr className="heading">
                        <td>
                            Item
                        </td>

                        <td>
                            Price
                        </td>
                    </tr>

                    {data.data.map((v,i) => {
                      return (<tr className="item last" key={i}><td><span style={{fontSize: 13}}>
                      {v.part_name} - {v.part_code}</span> x <span style={{fontSize: 13}}>{v.kuantitas} Pcs</span></td>
                    <td><span style={{fontSize: 13}}>Rp.{v.harga} / Item(s)</span></td></tr>)
                    })}


                    <tr className="heading" style={{marginTop: 20}}>
                        <td>
                            Shipping Detail
                        </td>

                        <td>
                            Cost
                        </td>
                    </tr>

                    <tr className="details">
                        <td>
                            <span style={{fontSize: 13,textTransform: 'Uppercase'}}>{order.shipping_name} x {order.shipping_weight}g</span>
                        </td>

                        <td>
                            <span style={{fontSize: 13}}>Rp.{order.shipping_value}</span>
                        </td>
                    </tr>

                    <tr className="total">
                        <td><span style={{fontSize: 15,fontWeight: 'bold'}}>Total Price</span></td>

                        <td>
                           <span style={{fontSize: 15}}>Rp.{order.subtotal}</span>
                        </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <table className="table table-striped table-bordered" style={{fontSize:13,fontFamily: 'Gotham',fontWeight: 600}}>
                    <tbody>
                    <tr>
                      <td colSpan="3">Order Summary</td>
                    </tr>
                    <tr>
                      <td width="35%">Products</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">{data.data.length} Products</td>
                    </tr>
                    <tr>
                      <td width="35%">Part Prices</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">Rp. {data.totalPrices}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Modal show={modalPayment.show}>
           <Modal.Header closeButton>
             <Modal.Title>Modal heading</Modal.Title>
           </Modal.Header>
           <Modal.Body>
             <iframe src={modalPayment.url}></iframe>
           </Modal.Body>
           <Modal.Footer>
             <Button variant="secondary" onClick={() => setModal({...modalPayment,show: false})}>
               Close
             </Button>
             <Button variant="primary" onClick={() => setModal({...modalPayment,show: false})}>
               Save Changes
             </Button>
           </Modal.Footer>
         </Modal>
         <Modal show={showNotice} onHide={() => setShowNotice(false)}>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                Payment Notification
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="alert alert-warning"><center><small>If Window Payment Not Show Automatically. You Can <a href={paymentUrl} target="_blank">Click This Link...</a></small></center></div>
            </Modal.Body>
        </Modal>
         <ToastsContainer store={ToastsStore}/>
        </Fragment>
    )
}
const style = {
  regiterLabel: {
    fontSize: '13px',
    fontWeight: 600
  }
}
const mapStateToProps = (state) => {
  return{
    cart: state.cart
  }
}
const dispatchToProps = (dispatch) => {
    return {
        setCart: (qty) => dispatch({type: 'SET_CART',cart: qty})
    }
}
export default connect(mapStateToProps,dispatchToProps)(Payment);
