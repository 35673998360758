import React, { Fragment,useEffect,useState } from 'react';
import {Nav} from 'react-bootstrap';
import {connect} from 'react-redux';
import API from '../Services/Api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Header = (props) => {
    const [cartQty, setCartQty] = useState(0);
    const changeMenu = (e) => {
      window.location.href = e.target.value;
    }
    const options = {
      title: 'Logout',
      message: 'Logout From Your Account?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            localStorage.setItem('APP_SESSION','{"status": false}');
            window.location.reload();
          }
        },
        {
          label: 'No',
          onClick: () => {
            console.log('Failed To Logout')
          }
        }
      ],
    };
    return (
    <React.Fragment>
      <div className="header">
        <div className="row">
          <div className="col-md-4">
            <a href="/" className="logo-link">
            <img src={process.env.PUBLIC_URL + '/img/modena-logo-2.png'}  className="mt-2" style={{float: "left",width: '42%',paddingTop: '5%'}} />
            </a>
          </div>
          <div className="col-md-8">
            <Nav className="justify-content-end top-nav main-menu" activeKey="/">
              <Nav.Item><Nav.Link href="/accessories" title="Accessories"><i className="fas fa-puzzle-piece"></i> Accessories</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/parts-knowledge"><i className="fas fa-lightbulb"></i> Parts Knowlegde</Nav.Link></Nav.Item>
              {(() => {
                  let session = JSON.parse(localStorage.APP_SESSION);
                  if(session['status'] == true){
                    if(session['type'] != 221){
                      return (<>
                      <Nav.Item><Nav.Link href="/part-search" title="Services Bulletin"><i className="fas fa-search"></i> Part Search</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link href="/scan"><i className="fas fa-qrcode"></i> QR Code</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link href="/bulletin" title="Services Bulletin"><i className="fas fa-tools"></i> Services Bulletin</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link onClick={() => confirmAlert(options)} title="Logout"><i className="fas fa-power-off"></i></Nav.Link></Nav.Item></>);
                    }
                    return (<>
                      <Nav.Item><Nav.Link href="/wishlist" title="My Wishlist"><i className="far fa-heart"></i> <span className='badge badge-info' style={style.badge}> {props.wishlist} </span></Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link href="/cart" title="My Shopping Cart"><i className="fas fa-shopping-cart"></i> <span className='badge badge-info' style={style.badge}> {props.cart} </span></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link href="/account" title="Login Member"><i className="fas fa-user"></i></Nav.Link></Nav.Item></>);
                  }else{
                      return (<><Nav.Item><Nav.Link href="/account"><i className="fas fa-user"></i> Login</Nav.Link></Nav.Item></>);
                  }
              })()}
            </Nav>
            <div className="mobile-menu">
              <select onChange={changeMenu} id="main-menu">
                <option value="/">Home</option>
                <option value="/parts-knowledge">Menu : Parts Knowledge</option>
                {(() => {
                  if(localStorage.getItem('APP_SESSION') === null){
                    localStorage.setItem('APP_SESSION','{"status": false}');
                    return (<><Nav.Item><Nav.Link href="/account"><i className="fas fa-user"></i> Login</Nav.Link></Nav.Item></>);
                  }else{
                    let session = JSON.parse(localStorage.APP_SESSION);
                    if(session['status'] == true){
                      if(session['type'] != '221'){
                        return (<>
                        <option value="/accessories">Menu : Accessories</option>
                        <option value="/scan">Menu : Scan QR Code</option>
                        <option value="/part-search">Menu : Part Search</option>
                        <option value="/bulletin">Menu : Services Bulletin</option>
                        <option value="/account">Menu : My Account</option></>)
                      }
                      return (<><option value="/wishlist">Menu : Wishlist</option>
                      <option value="/cart">Menu : Cart</option>
                      <option value="/account">Menu : My Account</option></>)
                    }else{
                      return (<><option value="/account">Menu : Login Account</option></>);
                    }
                  }
                })()}
              </select>
            </div>
          </div>
        </div>
        <div className="header-title" style={{display: props.line === 'line-title' ? 'block' : 'none'}}>{props.title}</div><div className="header-line" style={{width: props.line === 'line-title' ? '85%' : '100%'}}></div>

      </div>
    </React.Fragment>
    )
}
const style = {
  badge: {
    borderRadius: 9,
    fontSize: 10,
    marginLeft: -5,
    position: 'absolute'
  },
  lblCartCount: {
      fontSize: 12,
      background: '#ff0000',
      color: '#fff',
      padding: '0px 5px',
      verticalAlign: 'top',
      marginLeft: -10
  }
}
const mapStateToProps = (state) => {
  return{
    cart: state.cart,
    wishlist: state.wishlistItems,
    title: state.title
  }
}
const dispatchToProps = (dispatch) => {
    return {
        setCart: (qty) => dispatch({type: 'SET_CART',cart: qty}),
        setWishlist: (jml) => dispatch({type: 'SET_WISHLIST',wishlistItems: jml})
    }
}
export default connect(mapStateToProps,dispatchToProps)(Header);
