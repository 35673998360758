import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import {connect} from 'react-redux';
import PartImage from './PartImage';
import { Button,Modal } from 'react-bootstrap';
import {ToastsContainer, ToastsStore} from 'react-toasts';

function Detail(props) {
    const [specs, setSpecs] = useState({data: []});
    const [subtitution, setSubtitution] = useState({data: []});
    const [applied, setApplied] = useState({data: []});
    const [change, setChange] = useState({data: []});
    const [branch, setBranch] = useState({data: []});
    const [child, setChild] = useState([]);
    const [show, setShow] = useState(false);
    const [zoomImage, setZoomImage] = useState(false);
    const [image, setImage] = useState('');
    const [subLoading, setSubLoading] = useState(true);
    const [tab, setTab] = useState({
        specs: 'none',
        subs: 'none',
        applied: 'none',
        change: 'none',
        branch: 'none'
    });
    const [qty, setQty] = useState({
      id: null,
      value: 0
    });
    const session = JSON.parse(localStorage.APP_SESSION);
    const getDetail = () => {
      API.getPartInfo(props.part_id).then((res) => {
        setSpecs({data: res.data.data})
      });
    }
    const getSubtitution = () => {
      API.getPartSubtitution(props.part_id).then((res) => {
        setSubLoading(false);
        setSubtitution({data: res.data.data});
      });
    }
    const getApplied = () => {
      API.getAppliedItem(props.part_id).then((res) => {
        setApplied({data: res.data.data})
      });
    }
    const getChange = () => {
      API.getChangeItem(props.part_id).then((res) => {
        setChange({data: res.data.data})
      });
    }
    const getBranch = () => {
      API.getBranchItem(props.part_id).then((res) => {
        setBranch({data: res.data.data})
      });
    }
    const getChild = () => {
      API.getChildItem(props.part_id).then((res) => {
         setChild(res.data.data);
      });
    }
    const addToWishlist = (customer_id,part_id) => {
      let data = {'customer_id': customer_id,'model_id': localStorage.getItem('APP_ID_MODEL'),'part_id': part_id};
      API.postInsertWishlist(data).then((res) => {
          if(res.data.status == true){
            props.setWishlist(res.data.count);
            ToastsStore.success(res.data.msg);
          }else{
            ToastsStore.error(res.data.msg);
          }
      });
    }
    const addToCart = (modelId,partId) => {
      if(qty.value < 1){
        ToastsStore.warning("Sorry, This Item Not Available.");
      }else if(qty.id !== partId){
        ToastsStore.warning("Please Add Quantity For This Item.");
      }else if(qty.value > 0){
        let postData = {
          'konsumen_id': session['service_id'],
          'part_id': partId,
          'model_id': modelId,
          'qty': qty.value
        }
        API.postInsertCart(postData).then((res) => {
            if(res.data.status == true){
              ToastsStore.success(qty.value+' Item Added To Your Basket');
              setQty({
                id: null,
                value: 0
              });
              props.setCart(res.data.item);
            }
        }).catch((e) => {
          console.log(e);
        });

      }
    }
    const openPopup = () => {
      setShow(true);
    }
    const openSpec = () => {
        setTab({
            specs: 'block',
            subs: 'none',
            applied: 'none',
            change: 'none',
            branch: 'none',
            child: 'none'
        });
    }
    const openSubs = () => {
        setTab({
            specs: 'none',
            subs: 'block',
            applied: 'none',
            change: 'none',
            branch: 'none',
            child: 'none'
        });
        getSubtitution();
    }
    const openApplied = () => {
        setTab({
            specs: 'none',
            subs: 'none',
            applied: 'block',
            change: 'none',
            branch: 'none',
            child: 'none'
        })
        getApplied();
    }
    const openChange = () => {
        setTab({
            specs: 'none',
            subs: 'none',
            applied: 'none',
            change: 'block',
            branch: 'none',
            child: 'none'
        })
        getChange();
    }
    const openBranch = () => {
        setTab({
            specs: 'none',
            subs: 'none',
            applied: 'none',
            change: 'none',
            branch: 'block',
            child: 'none'
        })
        getBranch();
    }
    const openChild = () => {
      setTab({
          specs: 'none',
          subs: 'none',
          applied: 'none',
          change: 'none',
          branch: 'none',
          child: 'block'
      })
      getChild();
    }
    const selectQty = (e) => {
      setQty({
        id: e.target.name,
        value: e.target.value
      });
    }
    const popupImage = (v) => {
      setImage(v);
      setZoomImage(true);
    }
    const onImageError = (e) => {
        console.log(e.target)
    }

    useEffect(() => {
      getDetail();
      if(props.open === 'subs'){
        openSubs();
      }else if(props.open === 'specs'){
        openSpec();
      }
    },[]);
    const imageModel = 'https://apiecatalog.modena.com/'+props.modelImage;
    return (
        <tr className='part-info'>
        <td colSpan="9" className="part-detail">
          <div className="d-flex flex-row bd-highlight justify-content-center mb-2">
            <div className="p-1 pl-2 pr-2 bd-highlight menu-detail"><a className="tab-detail" onClick={openSpec}>Specification</a></div>
            <div className="p-1 pl-2 pr-2 bd-highlight menu-detail"><a className="tab-detail" onClick={openSubs}>Subtitution</a></div>
            <div className="p-1 pl-2 pr-2 bd-highlight menu-detail"><a className="tab-detail" onClick={openApplied}>Applied</a></div>
            <div className="p-1 pl-2 pr-2 bd-highlight menu-detail"><a className="tab-detail" onClick={openChange}>Change</a></div>
            {props.child === 'Y' ? <div className="p-1 pl-2 pr-2 bd-highlight menu-detail"><a className="tab-detail" onClick={openChild}>Assembly Parts</a></div>: ''}
            {localStorage.usersCode == 1 ? <div className="p-1 pl-2 pr-2 bd-highlight menu-detail"><a className="tab-detail" onClick={openBranch}>Branch</a></div> : ''}
          </div>
          <div style={{display: tab.specs}} className="tab-pane p-2">
            <div className="row">
              <div className="col-md-6">
                <h6 className="ml-2">{props.name} Specification</h6>
                <ul className="list-group list-group-flush ml-2 mb-2">
                  {specs.data.map((v,key) => (
                    <Fragment key={key}>
                    {console.log(v)}
                    <li className="list-group-item p-0 pb-1">Specification : {v.spec}</li>
                    <li className="list-group-item p-0 pb-1">Material : {v.material}</li>
                    <li className="list-group-item p-0 pb-1">Color : {v.color}</li>
                    <li className="list-group-item p-0 pb-1">Dimension : {v.dimension}</li>
                    <li className="list-group-item p-0 pb-1">Diameter : {v.diameter} (mm)</li>
                    <li className="list-group-item p-0 pb-1">Weight : {v.weight}</li>
                    <li className="list-group-item p-0 pb-1">Order Available : {props.availibility === 'Y' ? 'No': <><strong>Yes</strong></>}</li>
                    </Fragment>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
              {props.image != null ? <img src={props.newPart + props.image} width='80%' onClick={() => popupImage(props.newPart + props.image)} onError={e => { e.currentTarget.src = props.oldPart+props.image; }}  /> : ''}
              </div>
            </div>
          </div>
          <div style={{display: tab.subs}} className="tab-pane p-2">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="30%">Part Code</th>
                  <th width="30%">Part Name</th>
                  <th width="30%">Part Note/Remark</th>
                  <th width="10%">Stock</th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  if(subLoading){
                    return <tr>
                            <td colSpan={4}><center>Loading...</center></td>
                          </tr>
                  }
                })()}
                
                {subtitution.data.map((v,key) => (
                  <tr key={key}>
                    <td>{v.part_code}</td>
                    <td>{v.part_name}</td>
                    <td>{v.part_note}</td>
                    <td>{(() => {
                      if(v.qty > 0){
                        return (<h6 className="color-green"><i className="fas fa-check-circle"></i></h6>)
                      }else if(v.qty < 1){
                        return (<h6 className="color-yellow" style={{color: 'yellow'}}><i className="fas fa-exclamation-circle"></i></h6>)
                      }else {
                        return (<h6 className="color-red"><i className="fas fa-ban"></i></h6>)
                      }
                    })()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{display: tab.applied}} className="tab-pane p-2">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="40%">Model Number</th>
                  <th width="10%">Year</th>
                  <th width="30%">Lot Order</th>
                </tr>
              </thead>
              <tbody>
              {applied.data.map((v,key) => (
                <tr>
                  <th width="40%">{v.model_name}</th>
                  <th width="10%">{v.model_year}</th>
                  <th width="30%">{v.lot}</th>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{display: tab.change}} className="tab-pane p-2">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="20%">Date</th>
                  <th width="30%">Part Code</th>
                  <th width="35%">Change Note</th>
                </tr>
              </thead>
              <tbody>
                {change.data.map((v,key) => (
                <tr>
                  <td>{v.tanggal}</td>
                  <td>{v.part_code}</td>
                  <td>{v.description} - {v.before_after}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{display: tab.branch}} className="tab-pane p-2">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="40%">Location</th>
                  <th width="10%">Stock</th>
                </tr>
              </thead>
              <tbody>
                {branch.data.map((v,key) => (
                <tr>
                  <td>{v.location}</td>
                  <td>{v.qty}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{display: tab.child}} className="tab-pane p-1">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th width="30%">Part Name</th>
                  <th width="30%">Part Code</th>
                  <th width="10%">Qty</th>
                </tr>
              </thead>
              <tbody>
              {child.map((v,i) => (
                    <>
                    <tr>
                      <td>{v.part_name}</td>
                      <td>{v.part_code}</td>
                      <td>
                        {(() => {
                            if(session['type'] == '222' || session['type'] == '223'){
                              return v.qty;
                            }else{
                              return '-';
                            }
                        })()}
                      </td>
                    </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </td>
        <Modal
              show={show}
              onHide={() => setShow(false)}
              size="sm"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  You Need To Call Us
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center><img src={process.env.PUBLIC_URL + '/img/contactus200.png'} width="100%" /></center>
              </Modal.Body>
            </Modal>
            <Modal
              show={zoomImage}
              onHide={() => setZoomImage(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Part Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center><img src={image} width="100%" /></center>
              </Modal.Body>
            </Modal>
        <ToastsContainer store={ToastsStore}/>
      </tr>
    )
}
const stateToProps = (state) => {
  return {
    modelImage: state.modelImage,
    rootUrl: state.rootUrl,
    csmsURL: state.csmsURL,
    oldCsms: state.oldCsms,
    newPart: state.newPart,
    oldPart: state.oldPart
  }
}
const dispatchToProps = (dispatch) => {
  return {
      setCart: (qty) => dispatch({type: 'SET_CART',cart: qty}),
      setWishlist: (items) => dispatch({type: 'SET_WISHLIST',wishlistItems: items}),
      setListLoading: (status) => dispatch({type: 'SET_LIST_LOADING',listLoading: status})
  }
}
export default connect(stateToProps,dispatchToProps)(Detail);
