import React,{Fragment,useState,useEffect} from 'react';
import API from './../Services/Api';
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const Image = (props) => {
    let param = useParams();
    const [size, setSize] = useState();
    const [zoom, setZoom] = useState(0.25);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showImage, setShowImage] = useState(false);
    const [dimension, setDimension] = useState({
        width: '',
        height: ''
    });
    const [containerData, setContainerData] = useState({
        data: ''
    });
    const checkImage = ({target: img}) => {
        if(localStorage.getItem('APP_RELOAD_'+param.id) == 1){
        setTimeout(() => {
            setImage(zoom,img.offsetWidth,img.offsetHeight);
        },500)
        }
    }
    const zoomIn = () => {
        let img = document.getElementById("part-image");
        let zoomIn = zoom * 1.2;
        setImage(0.55,dimension.width,dimension.height);
    }
    const zoomOut = () => {
        let img = document.getElementById("part-image");
        let zoomIn = zoom * 1.2;
        setImage(0.45,dimension.width,dimension.height);
    }
    const setImage = (zoom,w,h) => {
        let width = (w * zoom * 2);
        let height = (h * zoom * 2);
        setDimension({
            width: width,
            height: height
        });
        document.getElementById("part-image").style.width = (w * zoom * 2)+"px";
        document.getElementById("part-image").style.height = (h * zoom * 2)+"px";
        var map = document.getElementsByClassName("map");
		for(var i = 0; i < map.length; i++)
		{
		map.item(i).style.top = (parseInt(map.item(i).style.top, 10) * zoom * 2) + "px";
		map.item(i).style.left = (parseInt(map.item(i).style.left, 10) * zoom * 2) + "px";
		map.item(i).style.width = (parseInt(map.item(i).style.width, 10) * zoom * 2 + 1) + "px";
        map.item(i).style.height = (parseInt(map.item(i).style.height, 10) * zoom * 2 + 1) + "px";
        }
    }
    const mapContainer = () => {
        API.getMapContainer(localStorage.getItem('APP_ID_MODEL_'+param['id'])).then((res) => {
            if(localStorage.getItem('APP_RELOAD_'+param.id) == 0){
                setContainerData({
                    data: res.data
                });
            }
            else{
                localStorage.setItem('ListContainer_'+param['id'],res.data);
            }
        });
    }
    const openImage = () => {
        setShowImage(true);
    }
    const onImageError = (e) => {
        console.log(e);
    }
    useEffect(() => {
        if(localStorage.getItem('APP_RELOAD_'+param.id) == 1){
            props.listLoading(false);
            setLoading(false);
        }else{
            props.listLoading(true);
            setLoading(true);
        }
        mapContainer();
    },[]);
    return (
        <Fragment>
            <div className="col-md-6 mb-2">
                <div id="pic_container" className="frame-image-part ui-widget-content">
                    <div id="draggable">
                        <img src={props.csmsUrl+props.image} onLoad={checkImage} id="part-image" />
                        <div id="mapContainer" dangerouslySetInnerHTML={{__html: localStorage.getItem('ListContainer_'+param['id'])}}></div>
                    </div>
                </div>
                <div className="frame-image-bottom mb-3">
                    <div className="row">
                        <div className="col-md-6 pl-2">
                            <div className="input-group mb-1 mt-2">
                                <button className="btn btn-warning btn-md btn-zoom" onClick={zoomIn}><i className="fa fa-plus"></i></button>
                                <button className="btn btn-warning btn-md btn-zoom" onClick={zoomOut}><i className="fa fa-minus"></i></button>
                                {props.modelImage == null ? '' : <button className="btn btn-warning btn-md btn-zoom" style={{marginLeft: 10}} onClick={() => openImage()}><i className="fas fa-camera"></i></button>}
                                
                            </div>
                        </div>
                    </div>
                </div>
             </div>
             <Modal
              show={showImage}
              onHide={() => setShowImage(false)}
              size="m"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Model Image
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center><img src={props.newPart+props.modelImage} width="100%"  onError={e => { e.currentTarget.src = props.oldPart+props.image; }} /></center>
              </Modal.Body>
            </Modal>
            <Modal
              show={loading}
              size="sm"
            >
              <Modal.Body>
                <center><Loader
                        type="ThreeDots"
                        color="orange"
                        height={60}
                        width={70}
                    /></center>
              </Modal.Body>
            </Modal>
        </Fragment>

    )
}
const stateToProps = (state) => {
    return {
        rootUrl: state.rootUrl,
        globalLoading: state.listLoading,
        modelImage: state.modelImage,
        csmsUrl: state.csmsURL,
        oldCsms: state.oldCsms,
        newPart: state.newPart,
        oldPart: state.oldPart
    }
}
const dispatchToProps = (dispatch) => {
    return {
        listLoading: (status) => dispatch({type:'SET_LIST_LOADING',listLoading: status})
    }
}
export default connect(stateToProps,dispatchToProps)(Image);
