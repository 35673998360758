import React,{Fragment,useState,useEffect,memo} from 'react';
import API from './../Services/Api';
import Detail from './Detail';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';
import { Button,Modal } from 'react-bootstrap';
import {
    useParams
} from "react-router-dom";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {search as searchIcon} from "../Images";

const List = memo((props) => {
    const $ = window.$;
    const [data, setData] = useState({data: []});
    const [filteredData, setFiltered] = useState({data: []});
    const [specs, setSpecs] = useState([]);
    const [id, setId] = useState(null);
    const [loading ,setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [session, setSession] = useState(JSON.parse(localStorage.APP_SESSION));
    const [qty, setQty] = useState({
      id: null,
      value: 0
    });
    const [showStatusOrder, setShowStatusOrder] = useState(false);
    const [statusOrder, setStatusOrder] = useState([]);
    const [itemCode, setItemCode] = useState('');
    const [items, setItems] = useState(0);
    const [openProps, setOpenProps] = useState('specs');
    const [search, setSearch] = useState(false);
    const [cart, setCartItem] = useState([]);
    const [cartId, setCartId] = useState([]);
    const [btnGccs, setBtnGccs] = useState(false);
    const [qtyItem, setQtyItem] = useState([]);
    const param = useParams();
    const getPartItem = () => {
      let user = session.type !== '222' ? '0' : session.type;
        API.getPartItem(param.id+'/'+user).then((res) => {
          props.setListLoading(false);
          localStorage.setItem('APP_ID_MODEL_'+param['id'],res.data.data[0].model_year_id);
          localStorage.setItem('PARTS_ITEM_'+param['id'],JSON.stringify(res.data));
          if(window.localStorage.getItem('APP_RELOAD_'+param.id) === null){
            setTimeout(() => {
                localStorage.setItem('APP_RELOAD_'+param.id,1);
                window.location.reload();
            },1000);
          }
          setData({
              data: res.data.data
          });
          setFiltered({
            data: res.data.data
          });
          setLoading(false);
        });
        
    }
    const tableLoad = (e) => {
        if(document.querySelector("[data-part-number='map_"+e+"']") != null){
            let tr = document.querySelector("[data-part-number='map_"+e+"']").classList.add('map_pointed');
        }
    }
    const tableLeave = (e) => {
        if(document.querySelector("[data-part-number='map_"+e+"']") != null){
            let tr = document.querySelector("[data-part-number='map_"+e+"']").classList.remove('map_pointed');
        }
    }
    const setOpen = (ids) => {
        if(ids !== id){
            setId(ids);
        }else{
            setId(null);
        }
    }
    const selectQty = (e) => {
      setQty({
        id: e.target.name,
        value: e.target.value
      });
    }

    const filterData = (d) => {
      let arr = [];
      if(d.length > 0){
        data.data.filter(fnc => {
          return fnc.part_name.toLowerCase().indexOf(d) !== -1;
        }).map(v => {
          arr.push(v);
        });
      }
      setFiltered({data: data.data});
    }
    useEffect(() => {
        if(localStorage.getItem('APP_RELOAD_'+param.id) == 1){
            props.setListLoading(false);
        }
        getPartItem();

        //filterData(props.filterChar);
    },[cart,cartId]);

    const addValueItem = (val,item) => {
      
      let index = qtyItem.findIndex(f => f.item === item);

      if(index > -1){
        const newitem = qtyItem.splice(index,1);
        setQtyItem(newitem);
      }
      setQtyItem([...qtyItem,{item: item,value: val.target.value}])
    }

    const onStatusOrder = (partId) => {
      API.getStatusOrder({ itemCode: partId }).then((res) => {
        setStatusOrder(res.data);
        setItemCode(partId);
        setShowStatusOrder(true);
      });
    }

    const submitGccs = () => {
      if(window.confirm("Item(s) will submit to GCCS App?")){
        setBtnGccs(true);
        
        const json = JSON.parse(sessionStorage.getItem('GCCS_DATA'));
        const items = [];

        console.log(cart);

        cart.map((v,i) => {
          items.push({
            "itemCode": v.item,
            "qty": v.qty
          })
        });

        const data = {
          "token": "23FB29BB50B8432E916549F1E45E59A3",
          "workOrderId": json.workorder,
          "technicianId": json.serviceId,
          "model": json.model,
          "itemList": items
        }

        API.postGccs(data).then((res) => {
          setBtnGccs(true);
          sessionStorage.clear();
          alert('Success! This session ended');
          localStorage.removeItem('APP_SESSION');
          window.location.href = "/";
        })
        .catch(err => {
          console.log(err);
        });
      }
    }

    return (
        <div className="col-md-6">
          {sessionStorage.getItem('APP_GCCS') !== null ? <button className='btn btn-success btn-md float-right mb-2' onClick={() => submitGccs()} disabled={btnGccs}><i class="fas fa-shopping-basket"></i>  Submit To GCCS</button> : ""}
            <div className="frame-list-part">
              <table className="table table-bordered" id="table-lists" style={{backgroundColor: '#ccc'}}>
                <thead>
                    <tr>
                    <th width="4%">No</th>
                    <th width="35%">Part</th>
                    <th width="25%">Description</th>
                    <th width="15%">Prices/Pcs</th>
                    <th width="15%">Qty</th>
                    <th width="3%">Stock</th>
                    <th width="5%">Moving Status</th>
                    </tr>
                </thead>
                <tbody>
                {loading ? <tr><td colSpan="6"><center><Loader type="ThreeDots" color="orange" height={20} width={35} /></center></td></tr> : ''}
                {data.data.filter(fnc => {
                  if(props.filter === 'F'){
                    return fnc.function === 'F';
                  }if(props.filter === 'T'){
                    return fnc.function === 'T';
                  }else if(props.filterChar.length > 0){
                    return fnc.part_name.toLowerCase().indexOf(props.filterChar) !== -1 || fnc.no.toLowerCase().indexOf(props.filterChar) !== -1 || fnc.part_id.toLowerCase().indexOf(props.filterChar) !== -1;
                  }else if(props.filterChar === ''){
                    return fnc;
                  }
                  else{
                    return fnc;
                  }
                }).map((v,i) => {
                    return (<Fragment key={i}>
                    <tr data-part={'map_'+v.part_id} id={v.part_id}
                    onFocus={() => tableLoad(v.part_id)}
                    onBlur={() => tableLoad(v.part_id)}
                    onMouseLeave={() => tableLeave(v.part_id)}
                    onMouseOver={() => tableLoad(v.part_id)} className="get_info" style={v.orderInavailable == "Y" ? {background: "#ccc"} : {}}>
                        <td className="p-0 pt-1" style={{textAlign: 'center'}} onClick={() => {
                            setOpen(v.part_id)
                            setOpenProps('specs')
                          }}>{v.no}
                        </td>
                        <td className="p-0" onClick={() => {
                            setOpen(v.part_id)
                            setOpenProps('specs')
                          }}>
                            <div className="p-2">{v.part_code}</div>
                            {session['type'] == '222' || session['type'] == '223'? <div className="p-2 part-icons" style={{borderTop: '1px solid #ddd',color: '#000',width: '100%'}}>
                                {v.fmtitemno}
                            </div> : ''}
                            
                          </td>
                        <td className="p-0" style={{textAlign: 'left'}} onClick={() => {
                            setOpen(v.part_id)
                            setOpenProps('specs')
                          }}>
                          <div className="p-2">{v.part_name}</div>
                          <div className="p-2 part-icons" style={{borderTop: '1px solid #ddd',backgroundColor: '#eee',color: '#000',width: '100%'}}>
                                {v.orderInavailable === 'Y' ? <><i class='fas fa-ban' style={{color: 'red'}}></i>&nbsp;&nbsp;</> : ''}
                                {v.function === 'F' ? <><i class='fas fa-cog'></i>&nbsp;&nbsp;</> : ''}
                                {v.picture !== null ? <><i class='fas fa-image'></i>&nbsp;&nbsp;</> : ''}
                                {v.best_seller === 4 ? <><i class='fas fa-award'></i>&nbsp;&nbsp;</> : ''}
                                {v.show_child === 'Y' ? <>"<i class='fas fa-puzzle-piece'></i>&nbsp;&nbsp;</> : ''}
                                {v.subtitutes === 'Y' ? <>"<i class='fas fa-recycle'></i>&nbsp;&nbsp;</> : ''}
                          </div>
                        </td>
                        <td className="p-2" onClick={() => {
                            setOpen(v.part_id)
                            setOpenProps('specs')
                          }}>{v.price}</td>
                        <td className="p-2">
                        {sessionStorage.getItem('APP_GCCS') ? <input type='number' onChange={e => addValueItem(e,v.part_id)} style={{width: 40}} id={`${v.part_id}_value`} readOnly={cartId.includes(v.part_id) ? true : false} /> : <div className='badge badge-info'>{v.qty}</div>}
                        
                            </td>
                            <td className="pl-1 pr-1" style={v.orderInavailable == "Y" ? {background: "#ccc"} : {}}  onClick={() => {
                                setOpen(v.part_id)
                                setOpenProps('specs')
                              }}>
                            {(() => {
                              if(v.stock > 0 && v.discontinue === 'N'){
                                return (<span class="badge badge-success">{v.stock}</span>)
                              }else if(v.stock == 0 && v.discontinue === 'N'){
                                return (<span class="badge badge-warning">No Stock</span>);
                              }else if(v.discontinue === 'Y'){
                                return (<span class="badge badge-danger">Discontinue</span>)
                              }else if(v.stock === null && v.discontinue === 'N'){
                                return (<span class="badge badge-warning">No Stock</span>)
                              }else{
                                return (<span class="badge badge-success">{v.stock}</span>)
                              }
                            })()}

                            <div className='mt-2'>
                            <img src={searchIcon} alt="search-icon" style={{width: 40, height: 40, borderRadius: "50%"}} onClick={() => onStatusOrder(v.part_id)} />
                            </div>
                            </td>
                            <td>
                              <div>
                                <h6>
                                  {v.moving3 === 'FM' ? <><div className='badge badge-primary'>{v.moving3}</div></> : ''}
                                  {v.moving3 === 'SM' ? <><div className='badge badge-danger'>{v.moving3}</div></> : ''}
                                  {v.moving3 === 'MM' ? <><div className='badge badge-success'>{v.moving3}</div></> : ''}
                                  {v.moving3 === null ? <><div className='badge badge-dark'>-</div></> : ''}
                                </h6>
                                </div>
                            </td>
                            
                        </tr>
                        {id == v.part_code ? <Detail id={v.part_code} part_id={v.part_id} name={v.part_name} image={v.picture} open={openProps} child={v.show_child} availibility={v.orderInavailable} /> : '' }
                        </Fragment>
                        )}
                    )}
    
                    </tbody>
                  </table>
                </div>
                <ToastsContainer store={ToastsStore}/>
                <Modal
                  show={showStatusOrder}
                  onHide={() => setShowStatusOrder(false)}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Status Order ({itemCode})
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {
                        statusOrder.length > 0 
                            ? 
                                <div className='p-2'>
                                    {
                                        statusOrder.length <= 5
                                            ?
                                                statusOrder.map((item, i) => {
                                                    return (
                                                        <div className='card p-2 mb-2' key={i}>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td width={150}>ETA</td>
                                                                        <td>:</td>
                                                                        <td>{item.ETA}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width={150}>Status Order</td>
                                                                        <td>:</td>
                                                                        <td>{item.STATUS_ORDER}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width={150}>Current Status</td>
                                                                        <td>:</td>
                                                                        <td>{item.CURRENT_STATUS}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })
                                            : 
                                                <div style={{height: 500, overflow: 'auto', overflowY: 'scroll'}}>
                                                    {
                                                        statusOrder.map((item, i) => {
                                                            return (
                                                                <div className='card p-2 mb-2' key={i}>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width={150}>ETA</td>
                                                                                <td>:</td>
                                                                                <td>{item.ETA}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width={150}>Status Order</td>
                                                                                <td>:</td>
                                                                                <td>{item.STATUS_ORDER}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td width={150}>Current Status</td>
                                                                                <td>:</td>
                                                                                <td>{item.CURRENT_STATUS}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                    }
                                </div>
                            : 
                                <div>
                                    There is no orders yet!..
                                </div>
                    }
                  </Modal.Body>
                </Modal>
              </div>
              
        )
    })
    
    const dispatchToProps = (dispatch) => {
        return {
            setCart: (qty) => dispatch({type: 'SET_CART',cart: qty}),
            setWishlist: (items) => dispatch({type: 'SET_WISHLIST',wishlistItems: items}),
            setListLoading: (status) => dispatch({type: 'SET_LIST_LOADING',listLoading: status})
        }
    }
    export default  connect(null,dispatchToProps)(List);
    