import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Subcategory from './Subcategory';
import { Modal } from 'react-bootstrap';
import {connect} from 'react-redux';

function Content(props){
    const [open, setOpen] = useState({
        id: null,
        open: 'none'
    });
    const [branchs, setBranchs] = useState({data: []});
    const [category, setCategory] = useState([]);

    const getData = (id) => {
        if(open.id == id && open.open == 'block'){
            setOpen({
                id: id,
                open: 'none'
            });
        }else{
            setOpen({
                id: id,
                open: 'block'
            });
        }
    }
    const GetBranch = () => {
        API.getBranch().then((res) => {
            setBranchs({data: res.data.data});
        });
    }
    const Redirect = (mypid) => {
        window.location.href = '/view/'+mypid;
    }
    const handleClose = () => {
        props.openModal(false);
    }

    useEffect(() => {
        GetBranch();
    },[]);
    return (
        <Fragment>
        <div className="container-custom mt-2">
        <div className="row">
        {branchs.data.map((v,key) => (
            <div className="col-md-6" key={key}>
                <div className="block-one">
                    {v.name}
                </div>

                {v.category.map((p,k) => (
                    <div className={v.category.length == 2 ? 'custom-box-right' : 'custom-box-left'} key={k}>
                        <div className="card-header-custom">{p.name}</div>
                        <Subcategory id={p.c_id} open={open} getData={(id) => getData(id)} />
                    </div>
                ))}
            </div>
        ))}
          </div>
        </div>
        <Modal show={props.modal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{props.modalData.length === 0 ? '-' : props.modalData[0].model_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                {props.modalData.map((v,i) => (
                            <div className={props.modalData.length === 1 ? 'col-md-12' : 'col-md-6'}>
                                <center>
                                    <a href={`/view/${v.model_year_part_id}`} target="_blank">
                                        <img className="img-Modal" src={props.csmsUrl+v.image} width="100%" />
                                        <h6 style={{border: '1px solid #ccc',backgroundColor: '#eee',padding: 5,marginBottom: 10}}>{v.part}</h6>
                                    </a>
                                </center>
                            </div>   
                ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {props.modalData.length === 0 ? '-' : props.modalData[0].model_description}
            </Modal.Footer>
        </Modal>
    </Fragment>
    )
}
const dispatchToProps = (dispatch) => {
    return {
        openModal: (status) => dispatch({type: 'OPEN_MODAL',modal: status}),
    }
}
const stateToProps = (state) => {
    return{
        myp_id: state.myp_id,
        url: state.rootUrl,
        csmsUrl: state.csmsURL,
        getId: state.id,
        modal: state.modal,
        modalData: state.dataModal
    }
}
export default connect(stateToProps, dispatchToProps)(Content);
