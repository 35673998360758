import React,{Fragment,useState,useEffect} from 'react';
import Header from '../Components/Header';
import Blank from '../NavPages/ScanPage/Blank';
import Search from '../Home/Search';
import API from '../Services/Api';
import {connect} from 'react-redux';
import {
    useHistory,
    useParams
} from "react-router-dom";

const Scan = (props) => {
    let param = useParams();
    const [searchData, setSearchData] = useState({
        partBarcode: null,
        modelNumber: null,
        partName: null
    });
    const [search, setSearch] = useState(false);
    const onchange = (e) => {
        if(e.target.value !== null){
            console.log('test');
            setSearchData({
                [e.target.name]: e.target.value
            })
        }
    }
    const searchByBarcode = (code) => {
        window.location.href = '/scan/byBarcode/'+btoa(code);
        //window.location.href = '/scan/byBarcode/'+searchData.partBarcode;
        window.location.reload();
    }
    const searchByModel = (e) => {
        e.preventDefault();
        window.location.href = "/search/byModel/"+searchData.modelNumber;
        window.location.reload();
    }
    const getPartNumber = (code) => {
        if(code !== null){
            setSearchData({...searchData,partBarcode: code});
            searchByBarcode(code);
        }
    }
    useEffect(() => {
        props.title('SCAN QR CODE');
        document.getElementById('main-menu').value = '#'+props.match.path;
        if(sessionStorage.getItem('MAIN_URL') !== null)
        {
            window.location.href = sessionStorage.getItem('MAIN_URL');
        }
    });
    return (
        <Fragment>
            <Header line="line-title" />
                <div className="container-custom pt-4">
                    <div className="row">
                            <div className="col-md-4 mb-2" style={{marginLeft: '0 !important'}}>
                                <form onSubmit={searchByBarcode}>
                                    <div className="input-group mb-0">
                                    <input type="text" className="form-control custom-input" placeholder="Input/Scan QR Code" name="partBarcode" onChange={onchange} readOnly value={searchData.partBarcode} />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4 mb-2" style={{marginLeft: '0px !important'}}>
                            <div className="input-group mb-0">
                                <input type="text" className="form-control custom-input" placeholder="Input Model Number" name="modelNumber" disabled />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2" style={{marginLeft: '0px !important'}}>
                                <form onSubmit="">
                                <div className="input-group mb-0">
                                <input type="text" className="form-control custom-input" placeholder="Input Lot Number" name="lotNumber" disabled />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary custom-button" type="submit" id="button-addon2" disabled>
                                    <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                </div>
                                </form>
                            </div>
                        <div className="col-md-12">
                            {props.content == 'Search' || props.match.path !== '/scan' ? <Search param={searchData} search={search} /> : <Blank partNumber={(e) => getPartNumber(e)} />}
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}
const mapDispatch = (dispatch) => {
    return {
        title: (title) => dispatch({type: "GET_TITLE",title: title})
    }
}
export default connect(null,mapDispatch)(Scan);
