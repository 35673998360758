import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import ReCAPTCHA from "react-google-recaptcha";
import RegisterOne from './RegisterOne';
import RegisterTwo from './RegisterTwo';
import RegisterThree from './RegisterThree';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

function Login(props){
  const [codeCaptcha, setCodeCaptcha] = useState('');
  const [data, setData] = useState({data: []});
  const [login, setLogin] = useState({data: []});
  const [error, setError] = useState(2);
  const [errorLogin, setErrorLogin] = useState(2);
  const [passwordErr, setPaswordErr] = useState(0);
  const [next, setNext] = useState(false);
  const [emailErr, setEmailErr] = useState(2);
  const [register, setRegister] = useState(2);
  const [loginData, setLoginData] = useState();
  const [errMessage, setErrMessage] = useState('');

  console.log(props.data != undefined ? '1' : '2');

  const onCaptcha = (value) => {
    setCodeCaptcha(value);
  }
  const onChange = (v) => {
    if(v.target.value == ''){
      document.getElementById(v.target.name).classList.add('is-invalid');
    }
    else{
      document.getElementById(v.target.name).classList.remove('is-invalid');
      if(v.target.name == 'email'){
        API.postCheckEmail({email: v.target.value}).then((res) => {
          if(res.data != 0){
            document.getElementById('email').value = '';
            document.getElementById('email').focus();
            setData({data: {
              ...data.data,
              email: undefined
            }})
            setEmailErr(1);
          }else{
            setData({data: {
              ...data.data,
              email: v.target.value
            }})
            setEmailErr(0);
          }
        }).catch((e) => {
          console.log(e);
        })
      }
      setData({
        data:{
          ...data.data,
          [v.target.name]: v.target.value
        }
      });
    }
  }
  const onChangeLogin = (v) => {
    console.log(v.target.name);
    if(v.target.value == ''){
      document.getElementById(v.target.name).classList.add('is-invalid');
    }
    else{
      document.getElementById(v.target.name).classList.remove('is-invalid');
      setLogin({
        data:{
          ...login.data,
          [v.target.name]: v.target.value
        }
      });
      console.log(login.data);
    }
  }
  const onLogin = () => {
    if(Object.keys(login.data).length < 2){
      setErrorLogin(1);
    }else{
      API.postLogin(login.data).then((res) => {
        if(res.data.status == true){
          localStorage.setItem('APP_SESSION','{"status": true,"service_id":"'+res.data.data.konsumen_id+'","type":"'+res.data.data.type+'","gudang_id":"'+res.data.data.gudang_id+'"}');
          setTimeout(function () {
            window.location.href = '/';
          }, 1500);
          setErrorLogin(4);
        }else{
          setErrMessage(res.data.data);
          setErrorLogin(3);
        }
      });
    }
  }
  const onRegister = (e) => {
    e.preventDefault();
    setRegister(3);
    setError(0);
    console.log(data);
    let checkPass = data.data.password != data.data.confirmPassword ? setPaswordErr(1) : setPaswordErr(0);
    if(Object.keys(data.data).length < 7){
      setError(1);
      setRegister(0);
    }else if(data.data.email == undefined){
      setError(1);
      setRegister(0);
    }else{
      setError(0);
      if(passwordErr == 0){
        /*sessionStorage.setItem('general',
          {email: data.data.email,
          name: data.data.firstName + ' ' + data.data.lastName,
          gender: data.data.gender,
          lang: data.data.country,
          password: data.data.password
        });*/
        setRegister(1);
        setNext(true);
      }
    }

  }
  return(
    <Fragment>
      <Header />
      {register == 3 ? <Loader fullPage loading /> : ''}
      <div className="container">
        <div className="row" style={style.rows}>
            <div className="col-md-12" style={style.columnTwo}>
            <center>
              <img src={process.env.PUBLIC_URL + '/img/logo.png'}  className="mt-4" style={{width: '35%',paddingTop: '4%'}} />
            </center>
            {errorLogin == 1 ? <div className="alert alert-danger mt-3" style={{fontSize: 13,fontWeight: 'bolder'}}><i className="fas fa-exclamation"></i> Please Fill All Forms.</div> : ''}
              {errorLogin == 3 ? <div className="alert alert-danger mt-3" style={{fontSize: 13,fontWeight: 'bolder'}}><i className="fas fa-exclamation"></i> {errMessage}.</div> : ''}
              {errorLogin == 4 ? <div className="alert alert-success mt-3" style={{fontSize: 13,fontWeight: 'bolder'}}>Welcome Back. This Page Will Refresh.</div> : ''}
              <input type="email" className="form-control mt-4" name="email_login" id="email_login" placeholder="Email*"  onBlur={onChangeLogin} />
              <input type="password" className="form-control mt-4" name="password_login" id="password_login" placeholder="Password*" onChange={onChangeLogin} />
              <div className="form-group form-check mt-3">
                <input type="checkbox" className="form-check-input" id="remember" name="remember" style={{marginTop: 7}} onBlur={onChangeLogin} />
                <label className="form-check-label" style={style.regiterLabel} onClick={onChangeLogin}>Remember Me</label>
              </div>
              <button className="btn btn-info btn-block btn-md mt-3" disabled={next ? true:false} style={{borderRadius: 0,background: 'orange',border: '1px solid orange'}} onClick={onLogin}>Signin</button>
              <a href="#" className="btn btn-link btn-sm mt-3">Forgot My Account</a>
            </div>
        </div>
      </div>
    </Fragment>
  )
}
const style = {
  container: {
    maxWidth: '100%',
    marginTop: 10
  },
  rows: {
    borderRadius: 5,
    display: 'inline-flex',
    width: '100%'
  },
  columnOne: {
    padding:15,
    borderRadius: 5,
    background: '#fff',
    border: '1px solid #ddd'
  },
  columnTwo: {
    background: '#fff',
    borderRadius: 5,
    border: '1px solid #ddd',
    marginLeft: '1%',
    height: 450,
    paddingLeft: '30%',
    paddingRight: '30%',
    paddingTop: '4%'
  },
  registerTitle: {
    fontSize: 18,
    borderBottom: '1px solid #ccc',
    paddingBottom: 10,
    fontFamily: 'Gotham'
  },
  loginTitle: {
    fontSize: 16,
    borderBottom: '1px solid #ccc',
    paddingBottom: 5,
    fontFamily: 'Gotham',
    color: '#666'
  },
  regiterLabel: {
    fontSize: '13px',
    fontWeight: 600
  }
}
export default Login;
