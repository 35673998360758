import React,{Fragment,useState,useEffect} from 'react';
import API from '../Services/Api';
import Header from '../Components/Header';
import {connect} from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Redirect } from 'react-router-dom';
import {ToastsContainer, ToastsStore} from 'react-toasts';

function Shipping(props){
    const [data, setData] = useState({data: []});
    const [account, setAccount] = useState([]);
    const [amount, setAmount] = useState(0);
    const [id, setId] = useState(0);
    const [rawTotal, setRawTotal] = useState(0);
    const [address, setAddress] = useState({data: []});
    const [services, setServices] = useState([]);
    const [shippingCost, setShippingCost] = useState(0);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [intCheck, setIntCheck] = useState(false);
    const [courierName, setCourierName] = useState('');
    const [interval, setInterval] = useState(false);
    const [shipping, setShipping] = useState({
      'name': null,
      'weight': null
    });
    const [enableService, setEnableService] = useState(true);
    let session = JSON.parse(localStorage.getItem('APP_SESSION'));

    const getItems = () => {
      API.getCartItems(session['service_id']).then((res) => {
        setData({data: res.data.data});
        setAmount(res.data.totalPrices);
        setSubTotal(res.data.totalPrices);
        setRawTotal(res.data.rawTotal);
      })
    }
    const getAddress = () => {
      API.getAddress(session['service_id']).then((res) => {
          setAddress(res.data);
      });
    }
    const getCost = (id,courier) => {
      setServices([]);
      setEnableService(true);
      API.getCost('?origin=152&destination='+id+'&weight=1000&courier='+courier).then((res) => {
          setServices(res.data.rajaongkir.results[0].costs);
          setCourierName(courier);
          setEnableService(false)
      });
    }
    const getAccount = () => {
      let konsumen_id = JSON.parse(localStorage.getItem('APP_SESSION')).service_id;
      API.getDataKonsumen(konsumen_id).then((res) => {
        setAccount(res.data.data[0]);
      });
    }
    const getCourier = (e) =>{
      if(e.target.value != ''){
        setShippingCost(services[e.target.value].cost[0].value);
        let tmp_price = subTotal.substr(0,subTotal.length - 3);
        let price = (parseInt(rawTotal) + parseInt(services[e.target.value].cost[0].value));
        let separator;
        let	price_string = price.toString(),
        	sisa 	= price_string.length % 3,
        	rupiah 	= price_string.substr(0, sisa),
        	ribuan 	= price_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
        	separator = sisa ? '.' : '';
        	rupiah += separator + ribuan.join('.');
        }
        setShipping({
          'name': courierName +' '+e.target.options[e.target.selectedIndex].text,
          'weight': 1000
        })
        setAmount(rupiah);
        let order_id = 'ECT-ORD-'+Math.round((new Date()).getTime() / 1000);
        let localData = {'nama_konsumen': account.nama_konsumen,'total': rupiah.replaceAll('.',''),'telepon': account.telepon,'email': account.email,'order_id': order_id};
        localStorage.setItem('APP_PAYMENT_DATA',JSON.stringify(localData));
      }else{
        setShippingCost(0);
        setAmount(subTotal);
      }
    }
    const create_key = (length) => {
       var result           = '';
       var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }
    const doPayment = () => {
      let json = JSON.parse(localStorage.getItem('APP_PAYMENT_DATA'));
      let dataOrder = {'konsumen_id': session.service_id,
                  'order_id': json.order_id,
                  'nama_konsumen': account.nama_konsumen,
                  'alamat': address.data.alamat + ', Kec.' +address.data.kecamatan +', Kel.'+ account.nama_kelurahan,
                  'kota': account.city_name,
                  'prov': account.province_name,
                  'telepon': account.telepon,
                  'email': account.email,
                  'shipping_name': shipping.name,
                  'shipping_weight': shipping.weight,
                  'shipping_value': shippingCost,
                  'subtotal': amount};
      API.postSaveOrder(dataOrder).then((res) => {
        if(res.data.status == true){
          window.location.href = '#/payment/'+json.order_id;
        }else{
          ToastsStore.error("Error. Something Wrong.");
        }
      });
    }
    useEffect(() => {
      getItems();
      getAddress();
      getAccount();

      if(sessionStorage.getItem('MAIN_URL') !== null)
        {
            window.location.href = sessionStorage.getItem('MAIN_URL');
        }
    },[]);
    return (
        <Fragment>
          <Header />
          <div className="row">
            <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="alert alert-primary" style={{fontSize: 13,fontWeight:'Gotham',fontWeight:600}}>Choose Shipping Method</div>
                    <table className="table table-bordered" style={{fontSize: 13,fontWeight: 600}}>
                      <tbody>
                      <tr>
                        <td>Province</td>
                        <td>{address.data.prov}</td>
                        <th>City</th>
                        <td>{address.data.kota}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td colSpan="3">{address.data.alamat}</td>
                      </tr>
                      <tr>
                        <td>Phone Number</td>
                        <td colSpan="3">{address.data.telepon}</td>
                      </tr>
                      </tbody>
                    </table>
                    <hr/>
                    <table className="table table-bordered" style={{fontSize: 13,fontWeight: 600}}>
                      <tbody>
                      <tr>
                        <td width="30%">Origin</td>
                        <td>JAKARTA PUSAT</td>
                      </tr>
                      <tr>
                        <td width="30%">Courier</td>
                        <td>
                          <select className="form-control" disabled={address.data.length == 0 ? true : false} onChange={(e) => getCost(address.data.id,e.target.value)} style={{fontWeight: 600,fontSize: 13}}>
                            <option>Select An Courier</option>
                            <option value="jne">JNE</option>
                            <option value="pos">POS INDONESIA</option>
                            <option value="tiki">TIKI</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td width="30%">Services</td>
                        <td>
                          <select className="form-control" style={{fontWeight: 600,fontSize: 13}} onChange={getCourier} disabled={enableService}>
                            <option value="">Select Courier Services</option>
                            {services.map((v,i) => {
                              return <option value={i} key={i}>({v.service}) | Est Hari : {v.cost[0].etd}</option>
                            })}
                          </select>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <table className="table table-striped table-bordered" style={{fontSize:13,fontFamily: 'Gotham',fontWeight: 600}}>
                    <tbody>
                    <tr>
                      <td colSpan="3">Order Summary</td>
                    </tr>
                    <tr>
                      <td width="35%">Your Items</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">{props.cart} Items</td>
                    </tr>
                    <tr>
                      <td width="35%">Products</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">{data.data.length} Products</td>
                    </tr>
                    <tr>
                      <td width="35%">Part Prices</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">Rp. {subTotal}</td>
                    </tr>
                    <tr>
                      <td width="35%">Shipping Cost</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">Rp. {shippingCost}</td>
                    </tr>
                    <tr>
                      <td width="35%">Total Prices</td>
                      <td width="5%"><center>:</center></td>
                      <td width="60%">Rp. {total == 0 ? amount : total}</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                          <button className="btn btn-sm btn-info btn-block" onClick={() => doPayment()} disabled={shippingCost == 0 ? true:false}>Make Payment</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastsContainer store={ToastsStore}/>
        </Fragment>
    )
}
const style = {
  regiterLabel: {
    fontSize: '13px',
    fontWeight: 600
  }
}
const mapStateToProps = (state) => {
  return{
    cart: state.cart
  }
}
const dispatchToProps = (dispatch) => {
    return {
        setCart: (qty) => dispatch({type: 'SET_CART',cart: qty})
    }
}
export default connect(mapStateToProps,dispatchToProps)(Shipping);
